import { renderingError } from "./error";
import { makeHTML } from "./converter";
import {
  DocKey,
  RenderingContext,
  RenderingHelpers,
  toKey,
  toKeyHash,
} from "./renderingContext";
import { matchSourceInTag } from "./resources";
import toposort from "toposort";
import md5 from "md5";
import { ProjectRefs } from "./helpers";
import { resolveRef } from "./validation";

export type RenderFragment = {
  // We compute a summary hash to check if the fragment has changed at all.
  hashSum: string;
  // Keep raw to make it easier to look up line numbers when reporting errors.
  raw: string;
  // Partial renderings or dependent imports.
  chunks: { html?: string; importKey?: DocKey }[];
  // Keys of all documents that this document directly imports.
  dependencies: DocKey[];
};

type Relationships = {
  // The documents that each document imports.
  deps: { [hash: string]: DocKey[] };
  // The documents that each document is imported by.
  // If empty, the document is never read.
  revDeps: { [hash: string]: DocKey[] };
};

/**
 * The set of currently cached render fragments.
 * A render fragment will not be recomputed unless the original file changes,
 * or a complete cache refresh is called for.
 */
export let cachedFragments: { [key: string]: RenderFragment } = {};

(window as any).cachedFragments = cachedFragments;

/**
 * The set of cached, fully-rendered HTML segments.
 * A populated fragment will be recomputed if its file changes or if any of
 * its imports changes.
 */
export const populatedFragments: { [key: string]: string } = {};

(window as any).populatedFragments = populatedFragments;

/**
 * Render a fragment and add it to the cache.
 * @param key The key of the fragment.
 * @param content The markdown content of the fragment.
 */
const renderFragment = (
  refs: ProjectRefs,
  context: RenderingContext,
  helpers: RenderingHelpers,
  docKey: DocKey,
  content: string
) => {
  const renderedHtml: string = makeHTML(refs, docKey.pid, helpers, content);

  const hashSum = md5(renderedHtml);
  if (cachedFragments[toKeyHash(docKey)]?.hashSum === hashSum) {
    // If the file has not changed, we do not need to repopulate it or
    // its dependencies. How exciting!
    return false;
  }

  const importRegex = /<\s*import[^>]*>/g;

  const htmls = renderedHtml.split(importRegex);
  const importTags = Array.from(renderedHtml.matchAll(importRegex)).map(
    (x) => x[0]
  );

  const chunks = [];
  const importKeys = [];

  // In theory, there should be exactly one more frag than import.
  if (htmls.length - importTags.length !== 1) {
    throw renderingError(
      "An assertion failed:",
      [
        <>
          "The number of html chunks is one larger than the number of import
          tags."
        </>,
      ],
      "This is probably an issue with Flagon."
    );
  }

  while (true) {
    const html = htmls.shift()!;
    chunks.push({ html });

    // Stop if we have run out of imports (i.e. we have just read the final
    // text fragment)
    if (importTags.length === 0) break;

    const importTag = importTags.shift()!;
    let src = matchSourceInTag(importTag);

    if (src === undefined) {
      throw renderingError(
        "Could not find src in an import tag.",
        [],
        "Check that all your import tags have src attributes."
      );
    }

    // This is a fully qualified local or linked document path
    const { pid: importPid, suffix } = resolveRef(docKey.pid, src, refs);

    const importKey = {
      pid: importPid,
      key: helpers.pathToKey[importPid][suffix],
    };

    if (!importKey.key) {
      throw renderingError(
        "An unresolvable import has been detected:",
        [
          <>
            <code>
              {refs[docKey.pid].info.name}/
              {helpers.keyToPath[docKey.pid][docKey.key]}
            </code>{" "}
            imports <code>{suffix}</code>{" "}
            {importPid !== context.projectId && (
              <>
                {" "}
                from <code>{refs[importPid].info.name}</code>
              </>
            )}
            , but <code>{suffix}</code> is not a document.
          </>,
        ],
        "Check your spelling or remove the import."
      );
    }

    if (importKey.key === undefined) debugger;

    // Add the import to the chunk
    chunks.push({ importKey });
    importKeys.push(importKey);
  }

  cachedFragments[toKeyHash(docKey)] = {
    chunks,
    hashSum,
    dependencies: importKeys,
    raw: content,
  };

  return true;
};

/**
 * Populate a fragment, injecting any dependent documents.
 * It is a precondition that any dependencies of this file have *already* been
 * populated.
 * @param key The key of the document whose framgent should be repopulated.
 */
const populateFragment = (refs: ProjectRefs, key: DocKey) => {
  const frag = cachedFragments[toKeyHash(key)];
  if (!frag) {
    throw renderingError(
      `Could not find fragment for ${refs[key.pid].files[key.key]!.name}`
    );
  }

  let result = "";

  frag.chunks.forEach((chunk) => {
    if (chunk.html !== undefined) {
      result += chunk.html;
    } else if (chunk.importKey !== undefined) {
      result += populatedFragments[toKeyHash(chunk.importKey)];
    }
  });

  // Store the new fragment
  populatedFragments[toKeyHash(key)] = result;
};
/**
 * Get the deps and reverse deps for each document.
 * Must be run AFTER rendering all fragments.
 * @param docKeys A list of all the document keys.
 */
const computeRelationships = (docKeys: DocKey[]) => {
  const deps: { [hash: string]: DocKey[] } = {};
  const revDeps: { [hash: string]: DocKey[] } = {};

  docKeys.forEach((key) => {
    deps[toKeyHash(key)] = [];
    revDeps[toKeyHash(key)] = [];
  });

  docKeys.forEach((key) => {
    if (!cachedFragments[toKeyHash(key)]) {
      throw renderingError(
        `Could not find fragment for document "${key.key}" in project ${key.pid}`,
        [],
        "This is likely an issue with Flagon."
      );
    }
    deps[toKeyHash(key)] = cachedFragments[toKeyHash(key)].dependencies;

    (cachedFragments[toKeyHash(key)].dependencies ?? []).forEach((dep) => {
      revDeps[toKeyHash(dep)].push(key);
    });
  });

  return { deps, revDeps };
};

/**
 * Get the order in which fragments in need of repopulating should be
 * repopulated.
 * @param docKeys A list of all the document keys.
 * @param relationships Child and parent relationships between files.
 */
const computeRenderOrder = (docKeys: DocKey[], { revDeps }: Relationships) => {
  // if X has a reverse dependency of Y, it means X must be populated
  // before y.

  const allDeps = Object.entries(revDeps).flatMap(([key, ps]) => {
    return ps.map((p) => [key, toKeyHash(p)]) as [string, string][];
  });

  // We only care about constraints between these document keys.
  // Why is this here? Won't this apply to ALL deps?
  const relevantDeps = allDeps.filter(
    ([key, revDep]) =>
      docKeys.findIndex((e) => toKeyHash(e) === key) !== -1 &&
      docKeys.findIndex((e) => toKeyHash(e) === revDep) !== -1
  );

  return toposort.array(docKeys.map(toKeyHash), relevantDeps).map(toKey);
};

/**
 * Compute the complete set of files which transitively depend on one of the
 * files in our input list. That is, all files which should be repopulated when
 * all of these files are repopulated.
 * Computes in breadth-first order.
 * @param keys The subset of document keys which we wish to compute the reverse transitive closure of.
 * @param relations Known relationships between documents.
 */
export const reverseTransitiveClosure = (
  keys: DocKey[],
  { revDeps }: Relationships
) => {
  const keySet = new Set<string>();
  const toAdd: string[] = [...keys.map(toKeyHash)];

  while (toAdd.length > 0) {
    const key = toAdd.shift()!;
    keySet.add(key);
    toAdd.push(...revDeps[key].map(toKeyHash));
  }

  return Array.from(keySet).map(toKey);
};

export {
  computeRenderOrder,
  renderFragment,
  populateFragment,
  computeRelationships,
};
