import firebase from "firebase";
import { useEffect, useState } from "react";
import {
  useListKeys,
  useListVals,
  useObjectVal,
} from "react-firebase-hooks/database";
import { v4 as uuidv4 } from "uuid";
import { Files } from "./project-view/sidebar/v2/fileOperations";

/******************************************************************************/
// Helpers

/**
 * An Index over T is a mapping from string ids to elements of type T.
 */
export type Index<T> = { [id: string]: T };

export type Ownership = "shared" | "owned" | "invited";
export type UserType = "shared" | "owner" | "invited";

/******************************************************************************/
// Initialise database hook

const db = firebase.database();

/******************************************************************************/
// User Management

export const setUserDisplayName = async (uid: string, displayName: string) => {
  await db.ref(`users/${uid}/displayName`).set(displayName);
  // for indexing
  await db.ref(`users/${uid}/displayNameLower`).set(displayName.toLowerCase());
};

export const setUserGithubLogin = async (uid: string, login: string) => {
  await db.ref(`users/${uid}/githubLogin`).set(login);
  // for indexing
  await db.ref(`users/${uid}/githubLoginLower`).set(login.toLowerCase());
};

/******************************************************************************/
// User Search

export type SimpleUser = {
  uid: string;
  displayName?: string;
  githubLogin: string;
};

const getUsersBy = async (field: string, prefix: string) => {
  const query = db
    .ref(`users`)
    .orderByChild(`${field}Lower`)
    .startAt(prefix.toLowerCase())
    .endAt(prefix.toLowerCase() + "\uf8ff");

  const results = (await query.once("value")).val();

  return Object.entries(results ?? {}).map(
    ([uid, userData]: [string, any]) => ({
      uid,
      displayName: userData.displayName,
      githubLogin: userData.githubLogin,
    })
  );
};

export const getUsersByDisplayName = async (prefix: string) =>
  await getUsersBy("displayName", prefix);

export const getUsersByGithubLogin = async (prefix: string) =>
  await getUsersBy("githubLogin", prefix);

export const useDisplayName = (uid: string) =>
  useObjectVal<string>(db.ref(`users/${uid}/displayName`));

export const useUser = (uid: string) =>
  useObjectVal<SimpleUser>(db.ref(`users/${uid}`));

/******************************************************************************/
// Workspaces

export type WorkspaceInfo = {
  name: string;
  ownerId: string;
};

export type WorkspaceUsers = {
  owner: string;
  shared: string[];
  invited: string[];
};

export type AllWorkspaceData = {
  info: WorkspaceInfo;
  users: WorkspaceUsers;
  projects: string[];
};

export const createEmptyWorkspace = async (
  workspaceName: string,
  ownerId: string
) => {
  const workspaceId = uuidv4();
  await db.ref(`workspaces/${workspaceId}`).set({
    info: { name: workspaceName },
    users: { owner: ownerId },
  });
  await db.ref(`users/${ownerId}/workspaces/owned/${workspaceId}`).set(true);
  return workspaceId;
};

/**
 * Get the entire workspace data value from the database as a structure.
 * @param workspaceId The ID of the workspace.
 * @returns All data stored for a workspace in a typed format.
 */
export const getAllWorkspaceData = async (
  workspaceId: string
): Promise<AllWorkspaceData> => {
  const data: any = await db
    .ref(`workspaces/${workspaceId}`)
    .once("value")
    .then((v) => v.val());

  return {
    info: data.info,
    users: {
      owner: data.users.owner,
      shared: Object.keys(data.users.shared ?? {}),
      invited: Object.keys(data.users.invited ?? {}),
    },
    projects: Object.keys(data.projects ?? {}),
  };
};

export const useWorkspaceInfo = (workspaceId?: string) => {
  const [res, setRes] = useState<WorkspaceInfo | undefined | null>(undefined);

  const [val] = useObjectVal<WorkspaceInfo>(
    db.ref(`workspaces/${workspaceId}/info`)
  );

  useEffect(() => {
    if (workspaceId === undefined) setRes(undefined);
    if (val === undefined) return;
    if (val === null) return setRes(null);
    setRes(val as WorkspaceInfo);
  }, [workspaceId, val]);

  return res;
};

export const useWorkspaceIds = (type: string, uid?: string) =>
  useListKeys(db.ref(`users/${uid}/workspaces/${type}`));

/**
 * Change the parent workspace of a project.
 * @param projectId The id of the project to move.
 * @param workspaceId The workspace (or none) to move it to.
 * @returns nothing
 */
export const setProjectWorkspace = async (
  projectId: string,
  workspaceId: string | undefined
) => {
  const info = (await db.ref(`projects/${projectId}/info`).once("value")).val();
  const owner = (
    await db.ref(`projects/${projectId}/users/owner`).once("value")
  ).val();

  // Do nothing if we are not changing the workspace
  if (workspaceId === info.workspace) return;

  // Moving from one workspace to another
  if (info.workspace && workspaceId) {
    await db
      .ref(`workspaces/${info.workspace}/projects`)
      .transaction((ps: string[]) => ps.filter((p) => p !== projectId));
    await db.ref(`workspaces/${workspaceId}/projects`).transaction((xs) => {
      if (!xs) xs = [];
      xs.push(projectId);
      return xs;
    });
    await db.ref(`projects/${projectId}/info/workspace`).set(workspaceId);
  }

  // Removing workspace, reparent to owner's loose projects
  else if (info.workspace && !workspaceId) {
    await db
      .ref(`workspaces/${info.workspace}/projects`)
      .transaction((ps: string[]) => ps.filter((p) => p !== projectId));
    await db.ref(`users/${owner}/projects/owned/${projectId}`).set(true);
    await db.ref(`projects/${projectId}/info/workspace`).remove();
  }

  // Adding workspace
  else if (!info.workspace && workspaceId) {
    await db.ref(`workspaces/${workspaceId}/projects`).transaction((xs) => {
      if (!xs) xs = [];
      xs.push(projectId);
      return xs;
    });
    await db.ref(`users/${owner}/projects/owned/${projectId}`).remove();
    await db.ref(`projects/${projectId}/info/workspace`).set(workspaceId);
  }
};

export const adjustAllProjects = async () => {
  const ws: Record<string, any> = (
    await db.ref(`workspaces`).once("value")
  ).val();

  let ws2: Record<string, any> = {};
  Object.keys(ws).forEach((wid: string) => {
    let w = ws[wid];
    let ps: string[] = [];
    Object.entries(w.projects).forEach(([k, v]) => {
      ps.push(k);
    });
    w.projects = ps;
    ws2[wid] = w;
  });
  await db.ref(`workspaces`).set(ws2);
};

/**
 * All projects in a workspace.
 * @param workspaceId The id of the workspace.
 * @returns All project ids assigned to that workspace.
 */
export const useWorkspaceProjectIds = (workspaceId: string) =>
  useListVals<string>(db.ref(`workspaces/${workspaceId}/projects`));

/**
 * Rename a workspace.
 * @param workspaceId The id of the workspace.
 * @param name The name to set.
 */
export const setWorkspaceName = async (workspaceId: string, name: string) => {
  await db.ref(`workspaces/${workspaceId}/info/name`).set(name);
};

/**
 * Remove a workspace entirely. Deparent all projects in the workspace.
 * @param workspaceId The id of the workspace.
 * @param userId The id of the user.
 */
export const deleteWorkspace = async (workspaceId: string) => {
  // Get all data attached to the project
  const data = await getAllWorkspaceData(workspaceId);

  // Remove every project in that workspace to the owner's loose projects
  const removals = data.projects.map(async (pid) => {
    await db.ref(`projects/${pid}/info/workspace`).set(null);
    await db.ref(`users/${data.users.owner}/projects/owned/${pid}`).set(true);
  });
  await Promise.all(removals);

  const userWorkspaces = {
    owned: [data.users.owner],
    shared: data.users.shared,
    invited: data.users.invited,
  };

  // Remove the workspace from every user's list of attached workspaces
  const userWorkspaceRemovals = Object.entries(userWorkspaces)
    .map(([type, uids]) =>
      uids.map((uid) => `users/${uid}/workspaces/${type}/${workspaceId}`)
    )
    .flat();

  await Promise.all(userWorkspaceRemovals.map((m) => db.ref(m).remove()));

  // Remove the workspace's own data
  await db.ref(`workspaces/${workspaceId}`).remove();
};

/**
 * Get whether the current user has collapsed a given workspace. Consults the database (users/${uid}/settings/collapsed-workspaces/${wid}).
 * @param wid The workspace id.
 * @param uid The user id.
 * @returns A react hook to get whether the workspace is collapsed.
 */
export const useWorkspaceCollapsed = (wid: string, uid: string) =>
  useObjectVal<boolean>(db.ref(`users/${uid}/settings/collapsed-workspaces/${wid}`));

/**
 * Set whether the current user has collapsed a given workspace. Updates the database (users/${uid}/settings/collapsed-workspaces/${wid}).
 * @param wid The workspace id.
 * @param uid The user id.
 * @param collapsed Whether the workspace is collapsed.
 * @returns nothing
 */
export const setWorkspaceCollapsed = async (wid: string, uid: string, collapsed: boolean) =>
  await db.ref(`users/${uid}/settings/collapsed-workspaces/${wid}`).set(collapsed ? true : null);

/******************************************************************************/

export type SourceData = { name: string; ref: string; workspaceName?: string };

export const getProjectIds = async (type: string, uid: string) =>
  Object.keys(
    (await db.ref(`users/${uid}/projects/${type}`).once("value")).val() ?? {}
  );
export const getWorkspaceIds = async (type: string, uid: string) =>
  Object.keys(
    (await db.ref(`users/${uid}/workspaces/${type}`).once("value")).val() ?? {}
  );

export const getWorkspaceInfo = async (wid: string) => ({
  wid,
  info: (
    await db.ref(`workspaces/${wid}/info`).once("value")
  ).val() as WorkspaceInfo,
});

export const getWorkspaceProjectIds = async (wid: string) =>
  Object.values<string>(
    (await db.ref(`workspaces/${wid}/projects`).once("value")).val() ?? {}
  );

export const setWorkspaceProjectIds = async (wid: string, pids: string[]) =>
  await db.ref(`workspaces/${wid}/projects`).set(pids);

export const getProjectInfo = async (pid: string) => ({
  pid,
  info: (
    await db.ref(`projects/${pid}/info`).once("value")
  ).val() as ProjectInfo,
});

export const getProjectFiles = async (pid: string) =>
  (await db.ref(`projects/${pid}/files`).once("value")).val() as Files;

export const searchSources = async (
  prefix: string,
  uid: string
): Promise<SourceData[]> => {
  const types = ["owned", "shared"];

  const pids = (
    await Promise.all(types.map((t) => getProjectIds(t, uid)))
  ).flat();
  const wids = (
    await Promise.all(types.map((t) => getWorkspaceIds(t, uid)))
  ).flat();
  const wpids = (await Promise.all(wids.map(getWorkspaceProjectIds))).flat();

  const allWorkspaceInfo = await Promise.all(wids.map(getWorkspaceInfo));
  let workspaceInfoMap: { [key: string]: WorkspaceInfo } = {};
  allWorkspaceInfo.forEach(({ wid, info }) => {
    workspaceInfoMap[wid] = info;
  });
  let allProjectInfo = await Promise.all(
    [...pids, ...wpids].map(getProjectInfo)
  );
  let projectInfoMap: { [key: string]: ProjectInfo } = {};
  allProjectInfo.forEach(({ pid, info }) => {
    projectInfoMap[pid] = info;
  });

  return [...pids, ...wpids]
    .map((pid) => {
      const projectName = projectInfoMap[pid].name;
      const wid = projectInfoMap[pid].workspace;

      let workspaceName = "";
      // accessible workspace
      if (wid && workspaceInfoMap[wid])
        workspaceName = workspaceInfoMap[wid].name;
      // hidden workspace
      if (wid && !workspaceInfoMap[wid]) workspaceName = "[Hidden workspace]";

      return { name: projectName, ref: pid, workspaceName };
    })
    .filter((x) => x.name.toLowerCase().startsWith(prefix.toLowerCase()));
};

/******************************************************************************/
// Projects

export type ProjectInfo = {
  name: string;
  main: string | undefined;
  workspace: string | undefined;
  pageSize: string | undefined;
  docPrefix: string | undefined;
  renderMode: string | undefined;
  preview: boolean | undefined;
};

export type AllProjectData = {
  info: ProjectInfo;
  users: ShareUsers;
};

export const createEmptyProject = async (
  projectName: string,
  ownerId: string,
  parentWorkspace?: string
) => {
  let projectId = "";
  let mainFileId = "";

  // Keep generating project IDs until a free one is found
  projectId = uuidv4();
  mainFileId = uuidv4();

  await db.ref(`projects/${projectId}`).set({
    files: { [mainFileId]: { type: "document", name: "main.flagon" } },
    documents: {
      [mainFileId]: {
        exists: true,
        history: {
          A0: {
            a: "",
            o: ["Start typing...!"],
            t: Date.now(),
          },
        },
      },
    },
    info: {
      name: projectName,
      main: mainFileId,
    },
    users: {
      owner: ownerId,
    },
  });
  await db.ref(`users/${ownerId}/projects/owned/${projectId}`).set(true);

  if (parentWorkspace !== undefined) {
    await setProjectWorkspace(projectId, parentWorkspace);
  }

  return projectId;
};

/**
 * Duplicate a project in its entirety. This will create a dependency on any shared images.
 * TODO: Duplicate all images in the bucket.
 * @param projectId The project to duplicate.
 */
export const duplicateProject = async (
  projectId: string,
  ownerId: string
) => {
  const oldProject: { info: ProjectInfo } = (await db.ref(`projects/${projectId}`).once('value')).val();
  const newProjectId = await createEmptyProject(`[COPY] ${oldProject.info.name}`, ownerId, oldProject.info.workspace);
  await db.ref(`projects/${newProjectId}`).set(oldProject);
  await db.ref(`projects/${newProjectId}/info/name`).set(`[COPY] ${oldProject.info.name}`);
}

/**
 * Get the users and info for a project.
 * @param projectId The ID of the project.
 * @returns All simple project data.
 */
export const useAllProjectData = (projectId: string) => {
  const [all, setAll] = useState<AllProjectData | undefined>(undefined);

  const [info] = useProjectInfo(projectId);
  const users = useShareUsers("project", projectId);

  useEffect(() => {
    if (!info || !users) return;
    setAll({ info, users });
  }, [info, users, projectId]);

  return all as AllProjectData | undefined;
};

/**
 * Gets the IDs of all projects owned by or assigned to the user.
 * @param type Which set of projects to get.
 * @param uid The user's id.
 * @returns A list of project IDs of the relevant type for this user.
 */
export const useProjectIds = (type: string, uid?: string) => {
  const [projects, setProjects] = useState<string[] | undefined>(undefined);
  const ref = db.ref(`users/${uid}/projects/${type}`);
  const [res, loading] = useListKeys(ref);

  useEffect(() => {
    if (loading) return;
    if (res === undefined) return;
    if (res === null) return setProjects([]);
    setProjects(res);
  }, [res, loading]);

  return projects;
};

/**
 * Get the users for a project.
 * @param projectId The ID of the project.
 * @returns The project's users data.
 */
export const useShareUsers = (type: ShareType, shareId: string) => {
  const [userData, setUserData] = useState<ShareUsers | undefined>(undefined);

  const [users, usersLoading] = useObjectVal<any>(
    db.ref(`${type}s/${shareId}/users`)
  );

  useEffect(() => {
    if (usersLoading) return;
    if (!users) return;

    const allUsers: ShareUsers = {
      owner: users.owner as string,
      shared: Object.keys(users.shared ?? {}),
      invited: Object.keys(users.invited ?? {}),
    };

    setUserData(allUsers);
  }, [users, usersLoading, shareId]);

  return userData;
};

/**
 * Get simple project info.
 * @param projectId The ID of the project.
 * @returns Simple project info.
 */
export const useProjectInfo = (projectId: string) =>
  useObjectVal<ProjectInfo>(db.ref(`projects/${projectId}/info`));

/**
 * Find out whether some document exists in a project.
 * @param projectId The project ID.
 * @param documentId The document ID.
 * @returns True if the document exists; false otherwise.
 */
export const useProjectExists = (projectId: string, documentId: string) =>
  useObjectVal(db.ref(`projects/${projectId}/documents/${documentId}/exists`));

/******************************************************************************/
// Shares

export type ShareUsers = {
  shared: string[];
  invited: string[];
  owner: string;
};

export type ShareType = "project" | "workspace";

export const createUserInvite = async (
  type: ShareType,
  shareId: string,
  userId: string
) => {
  await db.ref(`${type}s/${shareId}/users/invited/${userId}`).set(true);
  await db.ref(`users/${userId}/${type}s/invited/${shareId}`).set(true);
};

export const acceptUserInvite = async (
  type: ShareType,
  shareId: string,
  userId: string
) => {
  // Move the user in the project, from invited to shared
  await db.ref(`${type}s/${shareId}/users/shared/${userId}`).set(true);
  await db.ref(`${type}s/${shareId}/users/invited/${userId}`).remove();

  // Move the project in the user, from invited to shared
  await db.ref(`users/${userId}/${type}s/shared/${shareId}`).set(true);
  await db.ref(`users/${userId}/${type}s/invited/${shareId}`).remove();
};

export const rejectUserInvite = async (
  type: ShareType,
  shareId: string,
  userId: string
) => {
  // Remove traces of the invite
  await db.ref(`${type}s/${shareId}/users/invited/${userId}`).remove();
  await db.ref(`users/${userId}/${type}s/invited/${shareId}`).remove();
};

export const removeCollaborator = async (
  type: ShareType,
  shareId: string,
  userId: string
) => {
  // We want to remove the collaborator even if they've only been invited
  await db.ref(`${type}s/${shareId}/users/shared/${userId}`).remove();
  await db.ref(`users/${userId}/${type}s/shared/${shareId}`).remove();

  await db.ref(`${type}s/${shareId}/users/invited/${userId}`).remove();
  await db.ref(`users/${userId}/${type}s/invited/${shareId}`).remove();
};

export type Collaborator = { uid: string; invited: boolean };

/**
 * Get the users of a project or workspace in an easily consumed format.
 * @param type Project or workspace.
 * @param shareId The id of the node.
 * @returns A list of all attached users with whether or not they have been invited.
 */
export const useCollaborators = (type: ShareType, shareId: string) => {
  const [all, setAll] = useState<Collaborator[] | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  const users = useShareUsers(type, shareId);

  useEffect(() => {
    if (!users) return;

    const all: Collaborator[] = [
      ...(users.shared ?? []).map((x) => ({
        uid: x,
        invited: false,
      })),
      ...(users.invited ?? []).map((x) => ({
        uid: x,
        invited: true,
      })),
    ];

    setAll(all);
    setLoading(false);
  }, [users]);

  return [all, loading] as [Collaborator[] | undefined, boolean];
};
