import {
  getProjectFiles,
  getProjectInfo,
  ProjectInfo,
} from "../databaseHelpers";
import fileOperations, { Files } from "../project-view/sidebar/v2/fileOperations";
import { RenderingHelpers } from "./renderingContext";

/**
 * NOTE: currently no error will be thrown for attempting to dereference a project which the user does not have visibility to.
 */
export type ProjectRefData = {
  files: Files;
  getFullPath: (key: string) => string;
  getResourceURL: (key: string) => string;
  refs: { [path: string]: string };
  info: ProjectInfo;
};

export type ProjectRefs = { [pid: string]: ProjectRefData };

/**
 * We cache the references so they can be reused, unless we're clearing the cache.
 */
export let cachedRefs: ProjectRefs | undefined = undefined;

/**
 * Generating helpers for the entire swath of loaded data is extensive - we should cache it.
 */
export let cachedHelpers: RenderingHelpers | undefined = undefined;

/**
 * Get the full set of references across all projects transitively referenced from this one. This maps locally-qualified paths onto referenced projects for each project.
 */
export const makeRefs = async (projectId: string): Promise<void> => {
  let allProjectsInfo: ProjectRefs = {};

  let allReferencedProjects = [projectId];
  while (allReferencedProjects.length > 0) {
    const pid = allReferencedProjects.shift()!;

    // Do not scrape a project if its information is known
    if (allProjectsInfo[pid]) continue;

    const files = await getProjectFiles(pid);
    const { info } = await getProjectInfo(pid);
    const { getFullPath, getResourceURL } = fileOperations(pid, files);

    const refs: { [path: string]: string } = Object.assign(
      {},
      ...Object.entries(files)
        .filter(([_, file]) => file.type === "ref")
        .map(([key, file]) => ({ [getFullPath(key)]: file.ref! }))
    );

    allProjectsInfo[pid] = { refs, files, getFullPath, getResourceURL, info };

    allReferencedProjects = [...allReferencedProjects, ...Object.values(refs)];
  }

  cachedRefs = allProjectsInfo;

  makeHelpers();
};

/**
 * A set of small helpers which reduce mental or computational complexity
 * of the rest of the rendering function.
 */
const makeHelpers = (): void => {
  const projectRefs = cachedRefs!;

  // The set of all referenceable documents in [pid, ref] form.
  let documentKeys: { [pid: string]: string[] } = Object.assign(
    {},
    ...Object.entries(projectRefs).map(([pid, ref]) => ({
      [pid]: Object.entries(ref.files)
        .filter(([_, file]) => file.type === "document")
        .map(([key, _]) => key),
    }))
  );

  let documentKeysFlat = Object.entries(documentKeys).flatMap(([pid, keys]) =>
    keys.map((key) => ({ pid, key }))
  );

  const keyToPath: { [pid: string]: { [key: string]: string } } = Object.assign(
    {},
    ...Object.entries(projectRefs).map(([pid, ref]) => ({
      [pid]: Object.assign(
        {},
        ...documentKeys[pid].map((key) => ({
          [key]: ref.getFullPath(key),
        }))
      ),
    }))
  );

  const pathToKey: { [pid: string]: { [path: string]: string } } =
    Object.assign(
      {},
      ...Object.entries(keyToPath).map(([pid, ktp]) => ({
        [pid]: Object.assign(
          [],
          ...Object.entries(ktp).map(([key, path]) => ({ [path]: key }))
        ),
      }))
    );

  const resourcesByPath: { [pid: string]: { [path: string]: string } } =
    Object.assign(
      {},
      ...Object.entries(projectRefs).map(([pid, ref]) => ({
        [pid]: Object.assign(
          {},
          ...Object.entries(ref.files)
            .filter(([, file]) => file.type === "resource")
            .map(([key]) => ({ [ref.getFullPath(key)]: key }))
        ),
      }))
    );

  cachedHelpers = {
    pathToKey,
    keyToPath,
    documentKeys,
    documentKeysFlat,
    resourcesByPath,
  };
};
