import showdown from "showdown";
import { ProjectRefs } from "./helpers";
import { RenderingHelpers } from "./renderingContext";
import { resolveImageTag } from "./resources";

const replaceFencedBlocks: showdown.RegexReplaceExtension = {
  // Replace code blocks with divs
  type: "lang",
  regex: /(?:^|\n)```(.*)([^`]*)```/g,
  replace: `<div class="$1" markdown="1">$2</div>`,
};
const noindentDoubleTilde: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /~~ (.*)/g,
  replace: `<p class="noindent" markdown="1">$1</p>`,
};
const outdentSingleTilde: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /~ ?(.*)/g,
  replace: `<p class="outdent" markdown="1">$1</p>`,
};
const pagebreak: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<p>\s*<pagebreak>\s*<\/p>/g,
  replace: `</div></div><div class="page"><div class="page-content">`,
};
const columnbreak: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<p>\s*<columnbreak>\s*<\/p>/g,
  replace: `<div class="columnbreak"></div>`,
};
const addSectionModifiers: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<([^>]+)> ?((\.[a-zA-Z0-9-_]+[ \t]*)+)/g,
  replace: (full: string, a: string, b: string) =>
    `<${a} class="${b.replace(/\./g, "")}">`,
};
const noSingleLineParaTags: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<p>(<[^>]*>)<\/p>/g,
  replace: "$1",
};
// It is not legal in HTML to have paragraphs inside other paragraphs. Some of
// the rules above will create that situation, so we deliberately remove double
// opens and double closes to tidy up the generated raw HTML.
const removeEmptyParasOpen: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<p><p/g,
  replace: `<p`,
};
const removeEmptyParasClose: showdown.RegexReplaceExtension = {
  type: "output",
  regex: /<\/p><\/p>/g,
  replace: `</p>`,
};

const converter = new showdown.Converter({
  extensions: [
    () => [
      replaceFencedBlocks,
      noindentDoubleTilde,
      outdentSingleTilde,
      pagebreak,
      columnbreak,
      addSectionModifiers,
      noSingleLineParaTags,
      removeEmptyParasOpen,
      removeEmptyParasClose,
    ],
  ],
  smoothLivePreview: true,
  tables: true,
});

const makeHTML = (
  // context: RenderingContext,
  projectRefs: ProjectRefs,
  pid: string,
  helpers: RenderingHelpers,
  content: string
) => {
  // Run the following steps *before* the converter is run
  // (i.e. on markdown)
  const pre = (text: string) => {
    return text;
  };

  // Run the following steps *after* the converter is run
  // (i.e. on raw html)
  const post = (text: string) => {
    text = text.replaceAll(
      /[ \t]*<img [^>]*>[ \t]*/g,
      resolveImageTag(pid, projectRefs, helpers)
    );
    return text;
  };
  const renderedHtml = post(converter.makeHtml(pre(content)));

  return renderedHtml;
};

export { makeHTML };
