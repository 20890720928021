import { useMemo, useState } from "react";
import { Button, Divider, Row, Spin, Typography } from "antd";
import {
  setWorkspaceProjectIds,
  useShareUsers,
  useWorkspaceInfo,
  useWorkspaceProjectIds,
} from "../databaseHelpers";
import { Link, Navigate, useNavigate, useParams } from "react-router";
import { PageFrame } from "../PageFrame";
import {
  ArrowLeftOutlined,
  MenuUnfoldOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "./WorkspaceView.scss";
import { NewProjectModal } from "./NewProjectModal";
import ButtonGroup from "antd/lib/button/button-group";
import { EditWorkspaceModal } from "./EditWorkspaceModal";
import "./MainPage.scss";
import {
  OrderableList,
  ProjectItem,
  UserDisplayName,
} from "./Lists";
import { useContext } from "react";
import { UserContext } from "../UserContext";

const { Title } = Typography;

const WorkspaceView = () => {
  const { workspaceId } = useParams() as { workspaceId: string };
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);

  const workspace = useWorkspaceInfo(workspaceId);
  const user = useContext(UserContext);
  const users = useShareUsers("workspace", workspaceId);
  const navigate = useNavigate();

  return (
    <PageFrame
      headerItems={[
        {
          key: "home", icon: <ArrowLeftOutlined />, label: <Link to="/">Home</Link>
        }
      ]}
    >
      {workspace === undefined || users === undefined ? (
        <Spin className="loading-spinner" />
      ) : workspace === null ? (
        <Navigate to="/" />
      ) : (
        <div id="workspace">
          <Row className="title-row">
            <Title>
              {workspace.name}
              {users.owner === user?.uid ? (
                <></>
              ) : (
                <span className="workspace-author">
                  by <UserDisplayName userId={users.owner} />
                </span>
              )}
            </Title>
            {users.owner === user?.uid && (
              <ButtonGroup>
                <Button
                  type="dashed"
                  size="large"
                  onClick={() => setEditModalVisible(true)}
                >
                  <MenuUnfoldOutlined /> Edit
                </Button>
                <Button
                  type="primary"
                  color="green"
                  size="large"
                  onClick={() => setProjectModalVisible(true)}
                >
                  <PlusOutlined /> New Project
                </Button>
              </ButtonGroup>
            )}
          </Row>
          <Divider orientation="left">Projects</Divider>

          <WorkspaceProjectsList workspaceId={workspaceId} />

          <NewProjectModal
            visible={projectModalVisible}
            parentWorkspace={workspaceId}
            close={() => setProjectModalVisible(false)}
          />
          <EditWorkspaceModal
            workspaceId={workspaceId}
            visible={editModalVisible}
            close={() => setEditModalVisible(false)}
            afterDelete={() => navigate("/", { replace: true })}
          />
        </div>
      )}
    </PageFrame>
  );
};

const WorkspaceProjectsList = ({ workspaceId }: { workspaceId: string }) => {
  const [projectIds] = useWorkspaceProjectIds(workspaceId);
  const winfo = useShareUsers("workspace", workspaceId);
  const user = useContext(UserContext);

  const isOwner = useMemo(() => winfo?.owner === user?.uid, [winfo, user]);

  return (
    <OrderableList
      listClass="project-list"
      data={projectIds ?? []}
      renderItem={(pid, n) => (
        <ProjectItem
          pid={pid}
          index={n}
          ownership="owned"
          draggable={isOwner}
        />
      )}
      listId={"workspace-list"}
      setOrdering={(pids: string[]) =>
        setWorkspaceProjectIds(workspaceId, pids)
      }
    />
  );
};

export { WorkspaceView };
