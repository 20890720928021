
import "./App.scss";
import "./InitializeFirebase";

import { Route, BrowserRouter as Router, Routes } from "react-router";

import { AuthProvider } from "./UserContext";
import { MainPage } from "./main-page/MainPage";
import { AuthStateHandler } from "./AuthStateHander";
import ProjectView from "./project-view/ProjectView";
import { WorkspaceView } from "./main-page/WorkspaceView";

const App = () => (
  <AuthProvider>
    <AuthStateHandler />
    <Router>
      <Routes>
        <Route path="/project/:projectId" element={<ProjectView />} />
        <Route path="/workspace/:workspaceId" element={<WorkspaceView />} />
        <Route path="/" element={<MainPage />} />
      </Routes>
    </Router>
  </AuthProvider>
);

export default App;
