import { InboxOutlined } from "@ant-design/icons";
import { Modal, message } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import { UploadFile } from "antd/lib/upload/interface";
import firebase from "firebase";
import React, { useContext, useState } from "react";
import { UploadRequestOption } from "rc-upload/lib/interface";
import { v4 as uuidv4 } from "uuid";
import { ProjectInfoContext } from "../../contexts/ProjectInfoContext";
import { UploadModalContext } from "../../contexts/UploadModalContext";

const UploadModal = () => {
  const { id } = useContext(ProjectInfoContext);

  const { visible, setVisible } = useContext(UploadModalContext);

  const [confirmLoading] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setVisible(false);
  };

  const onNewFile = (info: UploadChangeParam<UploadFile<any>>) => {
    console.log(`New file uploaded: ${info.file.name}`);
  };

  const beforeUpload = (file: File) => {
    console.log(file.type);
    if (!file.type.match(/^image\/(jpg|jpeg|png|bmp|svg)/)) {
      message.error(`Only JPG, PNG, BMP and SVG images may be uploaded.`);
      return Promise.reject("Incorrect file type");
    }
    if (file.size >= 50 * 1024 * 1024) {
      message.error(
        `File too large (${Math.round(file.size / 1_000_000)}MB, max 50MiB)`
      );
      return Promise.reject("File too large");
    }
    return Promise.resolve(file);
  };

  const request = async (details: UploadRequestOption<any>) => {
    // Generate a new identifier
    const newFile = uuidv4();

    const storage = firebase.storage().ref(`projects/${id}/${newFile}`);

    console.dir(details);

    const putTask = storage.put(await (details.file as Blob), {
      contentType: (details.file as Blob).type,
      customMetadata: {
        originalFilename: (details.file as { name: string }).name,
      },
    });
    const onSnapshot = (snapshot: firebase.storage.UploadTaskSnapshot) => {
      (details.onProgress as any)?.({
        percent: (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
      });
    };
    const onError = (error: firebase.storage.FirebaseStorageError) => {
      (details.onError as any)?.({ message: error.message });
    };
    const onComplete = () => {
      (details.onSuccess as any)?.();
    };

    putTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      onSnapshot,
      onError,
      onComplete
    );
  };

  return (
    <Modal
      title="Upload file"
      open={visible}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      onOk={handleOk}
    >
      <Dragger
        name="file"
        multiple={true}
        onChange={onNewFile}
        beforeUpload={beforeUpload}
        customRequest={request}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>Click or drag a file here to upload</p>
        <p>JPG, PNG, BMP or SVG (max 50MB)</p>
      </Dragger>
    </Modal>
  );
};

export default UploadModal;
