import { Input, Modal } from "antd";
import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { createEmptyWorkspace } from "../databaseHelpers";
import { UserContext } from "../UserContext";

const NewWorkspaceModal = ({
  visible,
  close,
}: {
  visible: boolean;
  close: () => void;
}) => {
  const user = useContext(UserContext);
  const [name, setName] = useState("");

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) =>
    setName(e.target.value);

  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const createWorkspace = useCallback(async () => {
    console.log("Creating");
    setConfirmLoading(true);
    if (!user) {
      console.warn("No user");
      setConfirmLoading(false);
      return;
    }
    await createEmptyWorkspace(name, user.uid);
    setConfirmLoading(false);
    setName("");

    close();
  }, [name, setName, user, close]);

  return (
    <>
      <Modal
        open={visible}
        title="Create Workspace"
        onCancel={close}
        onOk={createWorkspace}
        okButtonProps={{ disabled: name.length <= 3 }}
        confirmLoading={confirmLoading}
      >
        <Input
          placeholder="e.g. Adventuring with Pride: Queer We Go Again!"
          onChange={onInputChange}
          value={name}
        />
      </Modal>
    </>
  );
};

export { NewWorkspaceModal };
