import { useCallback, useContext, useState } from "react";

import "./MainPage.scss";
import { NewProjectModal } from "./NewProjectModal";
import { EditWorkspaceModal } from "./EditWorkspaceModal";
import { NewWorkspaceModal } from "./NewWorkspaceModal";
import { PageFrame } from "../PageFrame";
import { UserContext } from "../UserContext";
import { Button, Divider, Row } from "antd";
import Title from "antd/lib/typography/Title";
import {
  PlusOutlined,
  PlusCircleOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import ButtonGroup from "antd/lib/button/button-group";
import { ProjectsList, WorkspaceList } from "./Lists";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { setProjectWorkspace } from "../databaseHelpers";
import firebase from "firebase";

const MainPage = () => {
  const user = useContext(UserContext);
  const [projectModalVisible, setProjectModalVisible] = useState(false);
  const [workspaceModalVisible, setWorkspaceModalVisible] = useState(false);
  const [editWorkspace, setEditWorkspace] = useState<string | undefined>(
    undefined
  );

  const onDragEnd = useCallback(async (result: DropResult) => {
    if (result.draggableId && result.destination?.droppableId) {
      const pid = result.draggableId;
      const wid = result.destination?.droppableId;
      const parent = wid === " " ? undefined : wid;

      await setProjectWorkspace(pid, parent);
    }
  }, []);


  const login = useCallback(() => {
    firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider());
  }, []);

  return (
    <PageFrame headerItems={[]}>
      {!user ? (<div style={{ textAlign: "center", paddingTop: "10vh" }}>
        <Title level={2}>You are not logged in</Title>
        <Button className="sign-in-with-github" style={{ fontSize: "1.5rem", height: "auto" }} onClick={login}>
          <GithubOutlined />
          <span>Sign in with GitHub</span>
        </Button></div>
      ) : (
        <div id="projects">
          <Row className="title-row">
            <Title>Projects &amp; Workspaces</Title>
            <ButtonGroup>
              <Button
                type="dashed"
                size="large"
                onClick={() => setProjectModalVisible(true)}
              >
                <PlusOutlined /> New Project
              </Button>
              <Button
                type="dashed"
                size="large"
                onClick={() => setWorkspaceModalVisible(true)}
              >
                <PlusCircleOutlined /> New Workspace
              </Button>
              {/* <Button
                type="primary"
                color="danger"
                size="large"
                onClick={() => adjustAllProjects()}
              >
                Adjust Workspaces
              </Button> */}
            </ButtonGroup>
          </Row>
          <Divider orientation="left">My Workspaces &amp; Projects</Divider>
          <DragDropContext onDragEnd={onDragEnd}>
            <WorkspaceList userId={user.uid} ownership="owned" />
            <Droppable droppableId=" ">
              {(provided) => (
                <div ref={provided.innerRef} className="project-drop-wrapper">
                  <ProjectsList userId={user.uid} ownership="owned" draggable />
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <Divider orientation="left">Shared with me</Divider>
            <WorkspaceList userId={user.uid} ownership="shared" />
            <ProjectsList userId={user.uid} ownership="shared" />
            <Divider orientation="left">My Invites</Divider>
            <WorkspaceList userId={user.uid} ownership="invited" />
            <ProjectsList userId={user.uid} ownership="invited" />
          </DragDropContext>
        </div>
      )}
      <NewProjectModal
        visible={projectModalVisible}
        close={() => setProjectModalVisible(false)}
      />
      <NewWorkspaceModal
        visible={workspaceModalVisible}
        close={() => setWorkspaceModalVisible(false)}
      />
      <EditWorkspaceModal
        workspaceId={editWorkspace}
        visible={editWorkspace !== undefined}
        close={() => setEditWorkspace(undefined)}
        afterDelete={() => { }}
      />
    </PageFrame>
  );
};

export { MainPage };
