import React, { useContext } from "react";
import { ProjectInfoContext } from "./ProjectInfoContext";

const defaultLocalProjectSettings = {
  useCombinedListing: false
};
const LocalProjectSettingsContext = React.createContext<LocalProjectSettings & { updateSettings: (newSettings: Partial<LocalProjectSettings>) => void }>(
  { ...defaultLocalProjectSettings, updateSettings: () => { } }
);

type LocalProjectSettings = {
  useCombinedListing: boolean;
}

const LocalProjectSettingsProvider = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => {
  const { id } = useContext(ProjectInfoContext);
  const item = `settings/project/${id}`;

  const [settings, setSettings] = React.useState<LocalProjectSettings>(() => {
    const savedSettings = localStorage.getItem(item);
    return savedSettings ? JSON.parse(savedSettings) : defaultLocalProjectSettings;
  });

  const updateSettings = (newSettings: Partial<LocalProjectSettings>) => {
    setSettings((prevSettings) => {

      const updatedSettings = { ...prevSettings, ...newSettings };

      localStorage.setItem(item, JSON.stringify(updatedSettings));

      return (updatedSettings);
    });

  }

  return (
    <LocalProjectSettingsContext.Provider value={{ ...settings, updateSettings }}>
      {children}
    </LocalProjectSettingsContext.Provider>
  );
};

export { LocalProjectSettingsContext, LocalProjectSettingsProvider };
