import { useCallback, useContext, useMemo, useState } from "react";
import { Button, Drawer, List, Row, Select, Spin, Checkbox, Switch, } from "antd";
import { ProjectSettingsDrawerContext } from "../../contexts/ProjectSettingsDrawerContext";

import "./ProjectSettingsDrawer.scss";
import { ProjectInfoContext } from "../../contexts/ProjectInfoContext";
import { FileOperationsContext, FilesContext } from "../../contexts/FilesContext";
import firebase from "firebase";
import {
  FileOutlined,
  FileZipOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { downloadZip } from "../../../saving/downloadZip";
import { downloadHtml } from "../../../saving/downloadHtml";
import { RenderContext } from "../../contexts/RenderContext";
import { UserContext } from "../../../UserContext";
import {
  setProjectWorkspace,
  useCollaborators,
  useWorkspaceInfo,
  useWorkspaceIds,
  duplicateProject,
} from "../../../databaseHelpers";
import { AddCollaboratorModal } from "../../../main-page/AddCollaboratorModal";
import { NewWorkspaceModal } from "../../NewWorkspaceModal";
import { CollaboratorEntry } from "./CollaboratorEntry";
import { pageSizes } from "../../../page-sizes";
import TextArea from "antd/lib/input/TextArea";
import { renderModes } from "../../../render-modes";
import { LocalProjectSettingsContext } from "../../contexts/LocalProjectSettingsContext";

const ProjectSettingsDrawer = () => {
  const user = useContext(UserContext);
  const { visible, setVisible } = useContext(ProjectSettingsDrawerContext);
  const project = useContext(ProjectInfoContext);
  const workspaceInfo = useWorkspaceInfo(project.info.workspace);
  const [workspaceIds] = useWorkspaceIds("owned", user?.uid);

  const [collabModalVisible, setCollabModalVisible] = useState(false);
  const [workspaceModalVisible, setWorkspaceModalVisible] = useState(false);

  const { useCombinedListing, updateSettings } = useContext(LocalProjectSettingsContext);

  const isOwner = useMemo(
    () => project.users.owner === user?.uid,
    [project.users.owner, user]
  );



  const onSetDocPrefix = useCallback(
    (val: string) => {
      let v = val === "" ? null : val;
      firebase.database().ref(`projects/${project.id}/info/docPrefix`).set(v);
    },
    [project.id]
  );

  const [collaborators, collaboratorsLoading] = useCollaborators(
    "project",
    project.id
  );

  return (
    <>
      <Drawer
        className="project-settings"
        open={visible}
        onClose={() => setVisible(false)}
        placement="left"
        title="Project settings"
      >
        <div className="setting-group main-doc">
          <MainDocControl />
        </div>
        <div className="setting-group main-doc">
          <PageSizeControl />
          <div style={{ height: "1rem" }}></div>
          <RenderModeControl />
          <div style={{ height: "1rem" }}></div>
          <PreviewModeControl />
        </div>
        <div className="setting-group collaborators">
          <p className="setting-group-heading">Collaborators</p>
          <List
            className=""
            bordered
            size="small"
            dataSource={collaborators}
            loading={collaboratorsLoading}
            renderItem={(c) => (
              <CollaboratorEntry
                c={c}
                id={project.id}
                type="project"
                owner={project.users.owner}
              />
            )}
          ></List>
          {isOwner && (
            <Button
              className="add-collaborator"
              onClick={() => setCollabModalVisible(true)}
            >
              <PlusOutlined />
              Add Collaborator
            </Button>
          )}
        </div>
        {workspaceInfo !== undefined && workspaceIds !== undefined && (
          <div className="setting-group workspace">
            <p className="setting-group-heading">Workspace</p>
            <Select
              value={project.info.workspace}
              disabled={project.users.owner !== user?.uid}
              onChange={async (w) => await setProjectWorkspace(project.id, w)}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Button
                    onClick={() => setWorkspaceModalVisible(true)}
                    style={{
                      width: "calc(100% - 8px)",
                      marginTop: 4,
                      marginLeft: 4,
                      marginRight: 4,
                    }}
                  >
                    (New Workspace)
                  </Button>
                </>
              )}
            >
              {workspaceIds.map((wid) => (
                <Select.Option key={wid} value={wid}>
                  <WorkspaceOption
                    workspaceId={wid}
                    ownerId={project.users.owner}
                  />
                </Select.Option>
              ))}
            </Select>
          </div>
        )}
        <div className="setting-group main-doc">
          <p className="setting-group-heading">Document Render Prefix</p>
          <TextArea
            rows={4}
            onChange={(e) => onSetDocPrefix(e.target.value)}
            value={project.info.docPrefix ?? ""}
          />
        </div>
        <div className="setting-group duplicate-project">
          <DuplicateButton />
        </div>
        <div className="setting-group combined-listing" style={{ display: "flex", alignItems: "center" }}>
          <p className="setting-group-heading" style={{ display: "inline", flexGrow: 1, marginBottom: 0 }}>Use combined file listing</p>
          <Switch checked={useCombinedListing} onChange={(s) => updateSettings({ useCombinedListing: s })} />
        </div>
        <div className="spacer"></div>
        <DownloadButtons />
      </Drawer>
      <AddCollaboratorModal
        type="project"
        id={project.id}
        visible={collabModalVisible}
        setVisible={setCollabModalVisible}
      />
      <NewWorkspaceModal
        visible={workspaceModalVisible}
        setVisible={setWorkspaceModalVisible}
        projectId={project.id}
      />
    </>
  );
};

const WorkspaceOption = ({
  workspaceId,
  ownerId,
}: {
  workspaceId: string;
  ownerId: string;
}) => {
  const workspace = useWorkspaceInfo(workspaceId);
  return ownerId === workspaceId ? (
    <span>[none]</span>
  ) : workspace === undefined || workspace === null ? (
    <Spin />
  ) : (
    <span>{workspace.name}</span>
  );
};

const MainDocControl = () => {
  const project = useContext(ProjectInfoContext);
  const { files } = useContext(FilesContext);
  const fileOperations = useContext(FileOperationsContext);

  const { getFullPath } = fileOperations;

  const documents = useMemo(
    () =>
      Object.entries(files ?? {})
        .filter(([, file]) => file.type === "document")
        .map(([key, file]) => ({ ...file, key })),
    [files]
  );

  const onSetMain = useCallback(
    (val: string) => {
      let v = val === "" ? null : val;
      firebase.database().ref(`projects/${project.id}/info/main`).set(v);
    },
    [project.id]
  );

  return (
    <>
      <p className="setting-group-heading">Main document</p>
      <Select
        value={project.info.main ?? ""}
        disabled={documents.length === 0}
        onChange={onSetMain}
        size="small"
      >
        {documents.map(({ key }) => (
          <Select.Option value={key} key={key}>
            {getFullPath(key)}
          </Select.Option>
        ))}
        <Select.Option value={""}>[None]</Select.Option>
      </Select>
    </>
  );
};

const PageSizeControl = () => {
  const project = useContext(ProjectInfoContext);

  const onChangeSize = (size: any) => {
    firebase.database().ref(`projects/${project.id}/info/pageSize`).set(size);
  };

  return (
    <>
      <p className="setting-group-heading">Project page size</p>
      <Select
        value={project.info.pageSize ?? "rpg-book"}
        onChange={onChangeSize}
        size="small"
      >
        {Object.entries(pageSizes).map(([key, size]) => (
          <Select.Option value={key} key={key}>
            {size.title}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

const DuplicateButton = () => {
  const project = useContext(ProjectInfoContext);
  const user = useContext(UserContext);
  const [duplicating, setDuplicating] = useState(false);

  const duplicate = useCallback(async () => {
    if (!user?.uid) return;
    setDuplicating(true);
    await duplicateProject(project.id, user?.uid);
    setDuplicating(false);
  }, [project.id, user?.uid])

  return (
    <Button
      loading={duplicating}
      disabled={!(user?.uid && project.users.owner === user.uid)}
      block
      style={{ background: "#ffeedd", borderColor: "orange" }}
      onClick={duplicate}>
      Duplicate project
    </Button>
  )
}

const PreviewModeControl = () => {
  const project = useContext(ProjectInfoContext);

  const onChangePreview = (preview: any) => {
    if (preview) {
      firebase.database().ref(`projects/${project.id}/info/preview`).set(preview);
    }
    else {
      firebase.database().ref(`projects/${project.id}/info/preview`).remove();
    }
  };

  return (
    <div style={{
      display: 'flex'
    }}>
      < p className="setting-group-heading" style={{ display: "inline-block", marginRight: 'auto' }
      }> Add "preview" watermark</p >
      <Checkbox checked={project.info.preview ?? false} onChange={(v) => onChangePreview(v.target.checked)}>
      </Checkbox>
    </div >
  );
};

const RenderModeControl = () => {
  const project = useContext(ProjectInfoContext);

  const onChangeSize = (size: any) => {
    firebase.database().ref(`projects/${project.id}/info/renderMode`).set(size);
  };

  return (
    <>
      <p className="setting-group-heading">Project render mode</p>
      <Select
        value={project.info.renderMode ?? "digital"}
        onChange={onChangeSize}
        size="small"
      >
        {Object.entries(renderModes).map(([key, mode]) => (
          <Select.Option value={key} key={key}>
            {mode.title}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

const DownloadButtons = () => {
  const fileOperations = useContext(FileOperationsContext);
  const project = useContext(ProjectInfoContext);
  const { files } = useContext(FilesContext);
  const { renderFunc } = useContext(RenderContext);

  const [runningDownload, setRunningDownload] = useState(false);

  const onDownloadZip = useCallback(async () => {
    setRunningDownload(true);
    if (!files) return;

    await downloadZip(
      { ...project.info, id: project.id },
      files,
      fileOperations
    );
    setRunningDownload(false);
  }, [project, files, fileOperations]);

  const onDownloadHtml = useCallback(async () => {
    setRunningDownload(true);
    if (!files) return;
    await downloadHtml(project.info, renderFunc);
    setRunningDownload(false);
  }, [project, renderFunc, files]);

  return (
    <div className="setting-group">
      <p className="setting-group-heading">Download project as</p>
      <Row className="download-project-buttons">
        <Button
          className="download-project"
          onClick={onDownloadZip}
          disabled={runningDownload}
        >
          <FileZipOutlined />
          <p>zip</p>
        </Button>
        <Button
          className="download-project"
          onClick={onDownloadHtml}
          disabled={runningDownload}
        >
          <FileOutlined />
          <p>HTML</p>
        </Button>
      </Row>
    </div>
  );
};

export { ProjectSettingsDrawer };
