import { renderingError } from "./error";
import { cachedFragments } from "./fragment";
import { ProjectRefs } from "./helpers";
import {
  DocKey,
  RenderingContext,
  RenderingHelpers,
  toKeyHash,
} from "./renderingContext";

/**
 * Given a project ID and a source string, determine if the source string goes through a Source.
 */
export const resolveRef = (
  pid: string,
  src: string,
  refs: ProjectRefs
): { pid: string; suffix: string } => {
  const chunks = src.toString().split("/");
  const prefixes = [chunks.shift()];
  while (chunks.length > 0) {
    prefixes.push(prefixes[prefixes.length - 1] + "/" + chunks.shift());
  }

  for (const prefix of prefixes) {
    for (const [path, ref] of Object.entries(refs[pid].refs)) {
      if (prefix === path) {
        // console.log('Found referential prefix: "' + prefix + '"');
        return resolveRef(ref, src.slice(prefix.length + 1), refs);
      }
    }
  }

  return { pid, suffix: src };
};

const validateImports = (
  context: RenderingContext,
  projectRefs: ProjectRefs,
  helpers: RenderingHelpers
) => {
  const { main, projectId } = context;
  const { keyToPath, pathToKey } = helpers;

  // Cycle detection
  type Chain = { docKey: DocKey; line: number; path: string }[];
  type FailedImport = { docKey: DocKey; line: number; path: string };

  const detectImportErrors = (
    docKey: DocKey,
    chain: Chain
  ): { cycle?: Chain; failedImport?: FailedImport } => {

    const repeatedElem = chain.findIndex(
      (v) => v.docKey.key === docKey.key && v.docKey.pid === docKey.pid
    );
    if (repeatedElem !== -1) {
      return { cycle: chain.slice(repeatedElem) };
    }

    const lines = (cachedFragments[toKeyHash(docKey)]?.raw)
      .split("\n")
      .map((x) => {
        let y = x;
        y.replaceAll(/(?=<!--)([\s\S]*?)-->/g, "");
        return y;
      });
    let lineNumber = 0;

    for (const line of lines) {
      lineNumber++;
      const matches = Array.from(
        line.matchAll(/<\s*import\s+src="([^"]*)"\s*>/g)
      );

      for (const [, path] of matches) {
        if (path === undefined) {
          return {
            failedImport: { docKey, line: lineNumber, path: "" },
          };
        }
        const ref = resolveRef(docKey.pid, path.toString(), projectRefs);

        const newKey = pathToKey[ref.pid][ref.suffix];

        if (!newKey) {
          return {
            failedImport: { docKey, line: lineNumber, path: path.toString() },
          };
        }
        const nestedImportError = detectImportErrors(
          { pid: ref.pid, key: newKey },
          [...chain, { docKey, line: lineNumber, path: path.toString() }]
        );
        if (nestedImportError.cycle || nestedImportError.failedImport)
          return nestedImportError;
      }
    }

    return {};
  };

  const importError = detectImportErrors({ pid: projectId, key: main! }, []);

  if (importError.cycle) {
    throw renderingError(
      "An import cycle has been detected:",
      importError.cycle.map(({ docKey, line, path }) => (
        <>
          <code>{keyToPath[docKey.pid][docKey.key]}</code> imports{" "}
          <code>{path}</code> on line
          {` ${line}`}
        </>
      ))
    );
  }

  if (importError.failedImport) {
    const { docKey, path, line } = importError.failedImport;
    throw renderingError(
      "An unresolvable import has been detected:",
      [
        <>
          <code>
            {projectRefs[docKey.pid].info.name}/
            {keyToPath[docKey.pid][docKey.key]}
          </code>{" "}
          imports <code>{path}</code> on line
          {` ${line}`}, but <code>{path}</code> is not a document.
        </>,
      ],
      "Check your spelling or remove the import."
    );
  }
};

/**
 * Checks that there are no duplicate file paths in the project.
 * This function may throw a rendering error.
 * @param context The rendering context.
 * @param helpers The rendering helpers.
 */
const validateFilenames = (
  context: RenderingContext,
  projectRefs: ProjectRefs,
  { keyToPath }: RenderingHelpers
) => {
  const duplicates = getDuplicatePaths(keyToPath);

  if (duplicates.length > 0) {
    throw renderingError(
      "There are documents with the same fully qualified path:",
      duplicates.map(({ pid, path, count }) => (
        <>
          <code>{path}</code> appears {count} times in project{" "}
          {projectRefs[pid].info.name}.
        </>
      )),
      `Rename any duplicated filenames before proceeding.`
    );
  }
};

const getDuplicatePaths = (keyToPath: {
  [pid: string]: { [path: string]: string };
}): { path: string; pid: string; count: number }[] => {
  const pathCounts: { [pid: string]: { [path: string]: number } } = {};
  Object.entries(keyToPath).forEach(([pid, ktp]) => {
    Object.values(ktp).forEach((path) => {
      pathCounts[pid] = pathCounts[pid] ?? {};
      pathCounts[pid][path] = (pathCounts[pid][path] ?? 0) + 1;
    });
  });

  const dupes: { path: string; pid: string; count: number }[] = [];
  Object.entries(pathCounts).forEach(([pid, counts]) => {
    Object.entries(counts).forEach(([path, count]) => {
      if (count > 1) dupes.push({ count, path, pid });
    });
  });
  return dupes;
};

export { validateImports, validateFilenames, getDuplicatePaths };
