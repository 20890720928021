import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  BookOutlined,
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  LeftOutlined,
  ReadOutlined,
} from "@ant-design/icons";
import { Spin, List, message, Button, Card } from "antd";
import { Link } from "react-router";
import {
  Ownership,
  useAllProjectData,
  useProjectIds,
  acceptUserInvite,
  rejectUserInvite,
  useDisplayName,
  useWorkspaceInfo,
  useShareUsers,
  ShareType,
  useWorkspaceIds,
  useWorkspaceProjectIds,
  useWorkspaceCollapsed,
  setWorkspaceCollapsed,
} from "../databaseHelpers";
import { UserContext } from "../UserContext";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "@hello-pangea/dnd";

const SimpleList = ({
  data,
  renderItem,
  listClass,
  grid,
}: {
  listClass: string;
  data: string[] | undefined;
  renderItem: (id: string, ix: number) => JSX.Element;
  grid?: boolean;
}) => {
  console.log("Rerendering list");
  console.log(data);
  return data === undefined ? (
    <Spin />
  ) : data.length === 0 ? (
    <></>
  ) : (
    <List
      className={listClass}
      dataSource={data}
      renderItem={renderItem}
      grid={grid ? { column: 2, gutter: 12 } : undefined}
    />
  );
};

const OrderableList = ({
  listClass,
  listId,
  data,
  renderItem,
  setOrdering,
}: {
  listClass: string;
  listId: string;
  data: string[];
  renderItem: (id: string, ix: number) => JSX.Element;
  setOrdering: (pids: string[]) => void;
}) => {
  const [localData, setLocalData] = useState<string[]>(data);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleReorder = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;
      const items = [...localData];
      const [movedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination!.index, 0, movedItem);
      console.log("Going");
      setLocalData(items);
      setOrdering(items);
    },
    [localData, setOrdering]
  );

  return (
    <DragDropContext onDragEnd={handleReorder}>
      <Droppable droppableId={"workspace-list"}>
        {(provided) => (
          <div ref={provided.innerRef}>
            <SimpleList
              listClass="projects"
              data={localData}
              renderItem={renderItem}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const ProjectItem = ({
  pid,
  buttons,
  ownership,
  index,
  draggable,
}: {
  pid: string;
  buttons?: JSX.Element;
  ownership: Ownership;
  index?: number;
  draggable?: boolean;
}) => {
  const project = useAllProjectData(pid);

  // Whether or not to add a link
  const LinkHoc = useCallback(
    ({ children }: { children: JSX.Element | JSX.Element[] }) =>
      ownership !== "invited" ? (
        <Link to={`/project/${pid}`} className="link">
          {children}
        </Link>
      ) : (
        <div className="link">{children}</div>
      ),
    [ownership, pid]
  );

  const DraggableHoc = useCallback(
    ({ children }: { children: JSX.Element }) =>
      draggable ? (
        <Draggable index={index!} draggableId={pid}>
          {(provided, _) => (
            <div
              className="draggable-project"
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                ...provided.draggableProps.style,
                margin: "0 0 6px 0",
              }}
            >
              {children}
            </div>
          )}
        </Draggable>
      ) : (
        <>{children}</>
      ),
    [draggable, index, pid]
  );

  const DisplayNamePortion = useCallback(
    () =>
      ownership !== "owned" ? (
        <span className="owner">
          by <UserDisplayName userId={project?.users?.owner ?? ""} />
        </span>
      ) : (
        <></>
      ),
    [ownership, project]
  );

  return (
    <DraggableHoc>
      <List.Item key={pid} className={`project-item ${ownership}`}>
        <LinkHoc>
          <BookOutlined className="item-icon" />
          <span className="project-name">{project?.info?.name ?? "..."}</span>
          <DisplayNamePortion />
          {!buttons ? (
            <></>
          ) : (
            <>
              <span className="space"></span>
              {buttons}
            </>
          )}
        </LinkHoc>
      </List.Item>
    </DraggableHoc>
  );
};

const WorkspaceItem = ({
  wid,
  buttons,
  ownership,
}: {
  wid: string;
  buttons?: JSX.Element;
  ownership: Ownership;
}) => {
  const workspace = useWorkspaceInfo(wid);
  const users = useShareUsers("workspace", wid);

  // Whether or not to add a link
  const LinkHoc = useCallback(
    ({ children }: { children: JSX.Element | JSX.Element[] }) =>
      ownership !== "invited" ? (
        <Link to={`/workspace/${wid}`} className="link">
          {children}
        </Link>
      ) : (
        <div className="link">{children}</div>
      ),
    [ownership, wid]
  );

  const DisplayNamePortion = useCallback(
    () =>
      ownership !== "owned" ? (
        <span className="owner">
          by <UserDisplayName userId={users?.owner ?? ""} />
        </span>
      ) : (
        <></>
      ),
    [ownership, users]
  );

  return (
    <List.Item key={wid} className={`workspace-item ${ownership}`}>
      <LinkHoc>
        <ReadOutlined className="item-icon" />
        <span className="workspace-name">{workspace?.name ?? "..."}</span>
        <DisplayNamePortion />
        {!buttons ? (
          <></>
        ) : (
          <>
            <span className="space"></span>
            {buttons}
          </>
        )}
      </LinkHoc>
    </List.Item>
  );
};

const ProjectsList = ({
  ownership,
  userId,
  draggable,
}: {
  ownership: Ownership;
  userId: string;
  draggable?: boolean;
}) => {
  const projectIds = useProjectIds(ownership, userId);
  const renderItem = useCallback(
    (pid: string, index: number) => {
      switch (ownership) {
        case "owned":
          return (
            <ProjectItem {...{ draggable, index, ownership, pid }} key={pid} />
          );
        case "shared":
          return <ProjectItem {...{ index, ownership, pid }} key={pid} />;
        case "invited":
          return <ShareInvite type="project" key={pid} id={pid} />;
      }
    },
    [draggable, ownership]
  );

  return (
    <SimpleList
      listClass="project-list"
      data={projectIds}
      renderItem={renderItem}
    />
  );
};

const ShareInvite = ({ type, id }: { type: ShareType; id: string }) => {
  const user = useContext(UserContext);

  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);

  const acceptInvite = async () => {
    if (!user) return message.error("You are not logged in!");

    setAcceptLoading(true);
    await acceptUserInvite(type, id, user.uid);
    setAcceptLoading(false);
  };
  const rejectInvite = async () => {
    if (!user) return message.error("You are not logged in!");
    setRejectLoading(true);
    await rejectUserInvite(type, id, user.uid);
    setRejectLoading(false);
  };

  const buttons = (
    <>
      <Button
        type="text"
        className="success"
        loading={acceptLoading}
        onClick={acceptInvite}
        disabled={acceptLoading || rejectLoading}
      >
        <CheckOutlined />
        Accept
      </Button>
      <Button
        type="text"
        danger
        loading={rejectLoading}
        onClick={rejectInvite}
        disabled={acceptLoading || rejectLoading}
      >
        <CloseOutlined />
        Reject
      </Button>
    </>
  );

  return type === "project" ? (
    <ProjectItem ownership="invited" pid={id} buttons={buttons} />
  ) : (
    <WorkspaceItem ownership="invited" wid={id} buttons={buttons} />
  );
};

const WorkspaceList = ({
  ownership,
  userId,
}: {
  userId: string;
  ownership: Ownership;
}) => {
  const [workspaceIds] = useWorkspaceIds(ownership, userId);

  const workspaceIdsWithoutSelf = useMemo(() => {
    const ids = new Set(workspaceIds);
    ids.delete(userId);
    return Array.from(ids);
  }, [userId, workspaceIds]);

  const renderItem = useCallback(
    (wid: string) => {
      switch (ownership) {
        case "owned":
          return <LongWorkspaceItem ownership="owned" wid={wid} />;
        case "shared":
          return <WorkspaceItem wid={wid} ownership="shared" />;
        case "invited":
          return <ShareInvite type="workspace" id={wid} />;
      }
    },
    [ownership]
  );

  return (
    <SimpleList
      data={workspaceIdsWithoutSelf}
      listClass={"workspace-list"}
      renderItem={renderItem}
    />
  );
};

const LongWorkspaceItem = ({
  wid,
  draggable,
  ownership,
}: {
  wid: string;
  draggable?: boolean;
  ownership: Ownership;
}) => {
  const user = useContext(UserContext);
  const workspace = useWorkspaceInfo(wid);
  const [assignedProjects] = useWorkspaceProjectIds(wid);
  const [collapsed] = useWorkspaceCollapsed(wid, user!.uid);

  const title = useMemo(() => {
    return (<>
      <Link to={`/workspace/${wid}`} className="link">
        <ReadOutlined className="item-icon" />
        <span className="workspace-name">{workspace?.name ?? "..."}</span>
        <span style={{ width: "100%" }}></span>
      </Link>
      <Button
        role="switch"

        icon={collapsed ? <LeftOutlined /> : <DownOutlined />}
        onClick={(e) => { setWorkspaceCollapsed(wid, user!.uid, !collapsed); }}
        style={{ border: 0, margin: "6px 12px" }}
        // Automatically unfocus the button when clicked
        onFocus={(e) => { e.target.blur(); }}
      >
      </Button>
    </>
    );
  }, [wid, workspace?.name, collapsed, user]);

  return (
    <List.Item key={wid} className="workspace-item">
      <Card
        title={title}
        bordered={false}
        bodyStyle={collapsed ? { padding: 0 } : {}}
      >
        <Droppable droppableId={wid}>
          {(provided) => (
            <div ref={provided.innerRef}>
              {collapsed ||
                (<><SimpleList
                  listClass="project-list"
                  data={assignedProjects}
                  renderItem={(pid, index) => (
                    <ProjectItem
                      {...{ ownership, pid, index }}
                      draggable={ownership === "owned"}
                    />
                  )}
                />
                  {provided.placeholder}
                </>)
              }
            </div>
          )}
        </Droppable>
      </Card>
    </List.Item>
  );
};

const UserDisplayName = ({ userId }: { userId: string }) => {
  const [name] = useDisplayName(userId);
  return <span className="display-name">{name ?? "..."}</span>;
};

export {
  UserDisplayName,
  ProjectsList,
  SimpleList,
  ProjectItem,
  WorkspaceList,
  OrderableList,
};
