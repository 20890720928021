import { MailOutlined, CloseOutlined } from "@ant-design/icons";
import { List, Tooltip, Button, Popconfirm } from "antd";
import { useContext, useState, useMemo } from "react";
import {
  Collaborator,
  useUser,
  removeCollaborator,
  ShareType,
} from "../../../databaseHelpers";
import { UserContext } from "../../../UserContext";
import "./CollaboratorEntry.scss";

const CollaboratorEntry = ({
  c,
  id,
  owner,
  type,
}: {
  c: Collaborator;
  id: string;
  owner: string;
  type: ShareType;
}) => {
  const { uid, invited } = c;
  const user = useContext(UserContext);

  const [popVisible, setPopVisible] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const isOwner = useMemo(() => user?.uid === owner, [user?.uid, owner]);

  const [collabUser] = useUser(c.uid);

  const onConfirm = async () => {
    setDeleteLoading(true);

    await removeCollaborator(type, id, uid);

    setDeleteLoading(false);
    setPopVisible(false);
  };

  return (
    <List.Item key={uid} className="collaborator-entry">
      {invited && <MailOutlined className="invite" />}
      <span className="display-name">{collabUser?.displayName ?? collabUser?.githubLogin}</span>
      {isOwner && (
        <span>
          <Tooltip title={invited ? "Revoke invite" : "Remove collaborator"}>
            <Button type="text" danger onClick={() => setPopVisible(true)}>
              <CloseOutlined />
            </Button>
          </Tooltip>
          <Popconfirm
            title="Are you sure?"
            visible={popVisible}
            onConfirm={onConfirm}
            onCancel={() => setPopVisible(false)}
            okButtonProps={{ loading: deleteLoading, danger: true }}
            placement="right"
          />
        </span>
      )}
    </List.Item>
  );
};

export { CollaboratorEntry };
