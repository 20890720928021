import { useContext } from "react";
import { Menu, Layout } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import FilesMenuV1 from "./v1/FilesMenu";
import { ProjectSettingsDrawerContext } from "../contexts/ProjectSettingsDrawerContext";
import { SidebarV2 } from "./v2/SidebarV2";
import { LocalProjectSettingsContext } from "../contexts/LocalProjectSettingsContext";

const Sidebar = () => {
  const { useCombinedListing } = useContext(LocalProjectSettingsContext);
  console.log(useCombinedListing);
  if (useCombinedListing) {
    return <SidebarV1 />;
  }
  else {
    return <SidebarV2 />;
  }
};

const SidebarV1 = () => {
  const projectSettingsDrawer = useContext(ProjectSettingsDrawerContext);

  return (
    <Layout.Sider className="sidebar" width={200}>
      <FilesMenuV1 />
      <Menu selectable={false} items={[{
        key: "settingsDrawerVisible",
        onClick: () => projectSettingsDrawer.setVisible(true),
        icon: <SettingOutlined />,
        label: "Project settings"
      }]} />
    </Layout.Sider>
  );
}

export { Sidebar };
