import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import firebase from "firebase";

import { editor } from "monaco-editor";
import MonacoEditor from "@monaco-editor/react";
import Firepad from "firepad";
import md5 from "md5";

import { Loading, ProjectInfoContext } from "./contexts/ProjectInfoContext";
import { OpenDocumentContext } from "./contexts/OpenDocumentContext";
import { RenderContext } from "./contexts/RenderContext";
import { PaneContext } from "./contexts/PaneContext";
import { EditorSizeContext } from "./contexts/EditorSizeContext";

import { useProjectExists } from "../databaseHelpers";
import "./Editor.scss";

const EditorPane = () => {
  const { id } = useContext(ProjectInfoContext);
  const { openDocument, setOpenDocument } = useContext(OpenDocumentContext);
  const { editorPane } = useContext(PaneContext);
  const [exists, loading] = useProjectExists(id, openDocument!);

  // Unopen a document if the document is deleted or can't be found
  useEffect(() => {
    if (!loading && !exists) {
      setOpenDocument(null);
    }
  }, [exists, loading, setOpenDocument]);

  const blankEditorClass = openDocument === null ? " blank" : "";
  return (
    <div className={`editor${blankEditorClass}`} ref={editorPane}>
      {loading ? (
        <Loading />
      ) : openDocument === null ? (
        <></>
      ) : exists ? (
        <Editor key={openDocument} documentId={openDocument} />
      ) : (
        <p className="file-not-found">
          The document could not be found.
          <br />
          It may have been deleted.
        </p>
      )}
    </div>
  );
};

const Editor = ({ documentId }: { documentId: string }) => {
  const { id } = useContext(ProjectInfoContext);
  const { renderFunc } = useContext(RenderContext);
  const { setOpenDocumentData } = useContext(OpenDocumentContext);
  const { width, resizing } = useContext(EditorSizeContext);

  const DEBOUNCE_TIMEOUT = 200; // ms

  const [changeTimeout, setChangeTimeout] = useState<number | null>(null);
  const [monaco, setMonaco] = useState<editor.IStandaloneCodeEditor | null>(
    null
  );

  // This is a hack so that we can set a timeout for the render function
  // within a function which sets the open document data.
  const renderFuncOutOfBand = useRef<any>(null);
  useEffect(() => {
    renderFuncOutOfBand.current = renderFunc;
  }, [renderFunc]);

  const onMonacoMount = useCallback(
    (m: editor.IStandaloneCodeEditor) => {
      setMonaco(m);

      Firepad.fromMonaco(
        firebase.database().ref(`projects/${id}/documents/${documentId}`),
        m
      );
    },
    [documentId, id]
  );

  // Dispose of the firepad editor when this component is unmounted.
  useEffect(() => {
    return () => {
      (monaco as { firepad: any } | null)?.firepad?.dispose();
    };
  }, [monaco]);

  const onMonacoChangeDebounce = useCallback((v: any, e: any) => {
    if (monaco === null) {
      console.info("Monaco instance not found; no edit");
      return;
    }
    const currentDocumentData = monaco.getModel()?.getValue();
    if (currentDocumentData === undefined) {
      console.info("No document data; no edit");
      return;
    }
    setOpenDocumentData(currentDocumentData);

    if (changeTimeout !== null) clearTimeout(changeTimeout);

    const renderTimeout = window.setTimeout(async () => {
      firebase
        .database()
        .ref(`projects/${id}/documents/${documentId}/hashSum`)
        .set(md5(currentDocumentData));

      if (typeof renderFuncOutOfBand.current === 'function')
        renderFuncOutOfBand.current(false);
    }, DEBOUNCE_TIMEOUT);

    setChangeTimeout(renderTimeout);
  }, [id, documentId, monaco, changeTimeout, setOpenDocumentData]);

  // Update the layout of the monaco instance only AFTER we are done resizing,
  // or if the width changes while we're not paying attention.
  useEffect(() => {
    if (!monaco) return;
    if (resizing) return;
    monaco.layout({
      width: width ?? 0,
      height: monaco.getContainerDomNode().clientHeight,
    });
  }, [resizing, monaco, width]);

  return (
    <>
      {/* {!editorLoaded && documentId !== undefined && <Loading />} */}

      <MonacoEditor
        defaultLanguage="markdown"
        defaultValue=""
        theme="vs-dark"
        // wrapperClassName="monaco-wrapper"
        options={{
          wordWrap: "on",
          minimap: { enabled: false },
          automaticLayout: false,
        }}
        width={width ?? 0}
        onMount={onMonacoMount}
        onChange={onMonacoChangeDebounce}
      />
    </>
  );
};

export default EditorPane;
