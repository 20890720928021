import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FileOperationsContext, FilesContext } from "./contexts/FilesContext";
import { ProjectInfoContext } from "./contexts/ProjectInfoContext";
import { RenderContext } from "./contexts/RenderContext";

import "./Output.scss";
import { Result, Spin } from "antd";
import Paragraph from "antd/lib/typography";
import { CloseCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { render } from "../rendering/render";

import { OpenDocumentContext } from "./contexts/OpenDocumentContext";
import { RenderingError } from "../rendering/error";
import { PaneContext } from "./contexts/PaneContext";
import { EditorSizeContext } from "./contexts/EditorSizeContext";

const Output = () => {
  const fileOperations = useContext(FileOperationsContext);
  const project = useContext(ProjectInfoContext);
  const { files } = useContext(FilesContext);
  const { openDocument, openDocumentData } = useContext(OpenDocumentContext);
  const { outputPane, filePane } = useContext(PaneContext);
  const { width } = useContext(EditorSizeContext);
  const { renderFunc, setRenderFunc } = useContext(RenderContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<JSX.Element | null>(null);
  const [renderedOutputScale, setRenderedOutputScale] = useState(1);
  const [renderedContent, setRenderedContent] = useState<string | null>(null);

  const renderedOutputRef = useRef<HTMLDivElement>(null);

  const [contentHeight, setContentHeight] = useState<number | undefined>(
    undefined
  );
  const [lastMain, setLastMain] = useState<string | undefined>(undefined);

  // Construct a modified render func based on known values
  useEffect(() => {
    const wrappedRenderFunc: (clearCache: boolean) => Promise<boolean> = async (clearCache: boolean) => {
      if (!files) return false;
      setLoading(true);
      let success = true;

      await render({
        projectId: project.id,
        // files,
        outputRef: outputPane!,
        main: project.info.main,
        docPrefix: project.info.docPrefix,
        // fileOperations,
        clearCache,
        openDocument,
        openDocumentData,
      })
        .then((result) => {
          setError(null);
          setRenderedContent(result as string);
        })
        .catch((reason: RenderingError) => {
          setRenderedContent(null);
          success = false;
          if (reason._isRenderingError) {
            setError(
              <>
                {reason.head}
                <br />
                <ul>
                  {reason.lines.map((line, i) => (
                    <li key={i}>
                      <CloseCircleOutlined className="error-marker" />
                      {line}
                    </li>
                  ))}
                </ul>
                {reason.foot}
              </>
            );
          } else {
            console.warn(error);
            setError(<>{reason.toString()}</>);
          }
        });
      setLoading(false);
      return success;
    };
    setRenderFunc(() => wrappedRenderFunc as any);
  }, [project.id, project.info.main, files, fileOperations, openDocumentData, openDocument, error, outputPane, setRenderFunc, project.info.docPrefix]);


  // Run the render func right away if a change of main causes renderFunc to change
  useEffect(() => {
    if (project.info.main !== lastMain && outputPane?.current && typeof renderFunc === 'function') {
      console.log(renderFunc);
      renderFunc(true);
    }
    if (project.info.main !== lastMain) {
      setLastMain(project.info.main);
    }
  }, [renderFunc, outputPane, lastMain, project.info.main]);

  // Override ctrl+s, and override pgup/pgdn
  useEffect(() => {
    const overrideKeys = (e: KeyboardEvent) => {
      if (e.key === "s" && e.ctrlKey) {
        e.preventDefault();
        renderFunc(true);
        return;
      }

      if (e.key === "PageDown" || e.key === "PageUp") {
        const o = outputPane?.current;
        if (!o) return;
        e.preventDefault();

        // Get page in middle of output pane
        const divs = document.elementsFromPoint(o.clientWidth / 2 + o.offsetLeft, o.clientHeight / 2 + o.offsetTop).filter((x) => x.classList.contains("page"));
        if (divs.length === 0) return;

        const next = (e.key === "PageDown") ? divs[0].nextSibling : divs[0].previousSibling;
        if (!next) return;

        o.scrollTo({ top: (next as any as HTMLDivElement).offsetTop * renderedOutputScale, behavior: "auto" });
        return;
      }

    };
    window.addEventListener("keydown", overrideKeys);
    return () => {
      window.removeEventListener("keydown", overrideKeys);
    };
  }, [renderFunc, outputPane, renderedOutputScale]);


  const outputWidth = useMemo(() => {
    if (!filePane?.current) return 100;
    if (!width) return 100;
    return document.body.offsetWidth - filePane.current.offsetWidth - width;
  }, [width, filePane]);

  // When the editor is resized, recompute the scale of the rendered output.
  useEffect(() => {

    setTimeout(() => {
      if (
        !renderedOutputRef.current ||
        !outputPane?.current ||
        !filePane?.current
      )
        return;
      const pageWidth =
        renderedOutputRef.current.querySelector(".page:first-child")
          ?.clientWidth ?? 100;

      const outputAreaWidth = outputPane.current.clientWidth;
      const outputMargin = 16;

      setRenderedOutputScale((outputAreaWidth - outputMargin * 2) / pageWidth);
    }, 1);

  }, [outputWidth, renderedOutputRef, outputPane, renderedContent, filePane]);

  useLayoutEffect(() => {
    setContentHeight(renderedOutputRef.current?.scrollHeight);
  }, [renderedContent, width]);

  return (
    <>
      <section
        className={
          "output" +
          (renderedContent ? " has-content" : "") +
          (" " +
            (project.info.pageSize === undefined
              ? "rpg-book"
              : project.info.pageSize)) +
          (" " +
            (project.info.renderMode === undefined
              ? "digital"
              : project.info.renderMode)) +
          (project.info.preview === true
            ? " preview"
            : "")
        }
        ref={outputPane}
        style={{ width: outputWidth }}
      >
        {!renderedContent && !error && (
          <p className="no-content">output will appear here</p>
        )}
        {error && (
          <Result
            status="error"
            title="A problem was found during rendering"
            subTitle="Please fix the problem listed below and try again."
          >
            <Paragraph>{error}</Paragraph>
          </Result>
        )}
        {renderedContent && (
          <div
            className="render-wrapper"
            style={{
              height: `calc((${contentHeight}px * ${renderedOutputScale}) + 16px)`,
              paddingBottom: 0,
              overflow: "hidden",
            }}
          >
            <div
              className="rendered-output"
              ref={renderedOutputRef}
              style={{
                transform: `scale(${renderedOutputScale})`,
                marginBottom: -10,
              }}
              dangerouslySetInnerHTML={{ __html: renderedContent }}
            />
          </div>
        )}
      </section>
      <div className={`loading-indicator${loading ? " loading" : ""}`}>
        <Spin indicator={<LoadingOutlined spin />} />
      </div>
    </>
  );
};

export default Output;
