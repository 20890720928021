import { VerticalAlignMiddleOutlined } from "@ant-design/icons";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import Draggable, {
  DraggableData,
  DraggableEventHandler,
} from "react-draggable";
import { getTotalSize, PageSize } from "../page-sizes";
import { RenderMode } from "../render-modes";
import { EditorSizeContext } from "./contexts/EditorSizeContext";
import { OpenDocumentContext } from "./contexts/OpenDocumentContext";
import { PaneContext } from "./contexts/PaneContext";
import { ProjectInfoContext } from "./contexts/ProjectInfoContext";

import "./EditorResizer.scss";

/**
 * Get the size such that one A4 page perfectly fits, based on the height
 * of the output pane.
 */
const computeDefaultOutputPaneSize = (
  pageSize: PageSize | undefined,
  renderMode: RenderMode | undefined
) => {
  const outputPane = document.getElementsByClassName(
    "output"
  )[0] as HTMLDivElement;

  const clearSpace = 16; // px on each side

  const scrollbarWidth = outputPane.offsetWidth - outputPane.clientWidth;
  const availableHeight = outputPane.clientHeight;

  const size = getTotalSize(pageSize, renderMode);
  const aspectRatio = size.widthmm / size.heightmm;

  const pageHeight = availableHeight - clearSpace * 2;
  const pageWidth = pageHeight * aspectRatio + clearSpace * 2;

  const outputPaneWidth = pageWidth + scrollbarWidth;
  return outputPaneWidth;
};

const EditorResizer = () => {
  const { width, setWidth, setResizing } = useContext(EditorSizeContext);
  const { outputPane, editorPane, filePane } = useContext(PaneContext);
  const { openDocument } = useContext(OpenDocumentContext);
  const { info } = useContext(ProjectInfoContext);

  const [windowWidth, setWindowWidth] = useState(document.body.clientWidth);

  const mainWidth = useMemo(() => {
    if (!filePane?.current) return windowWidth;
    return windowWidth - filePane.current.offsetWidth;
  }, [filePane, windowWidth]);

  const returnToDefaultSize = useCallback(() => {
    setTimeout(() => {
      if (!outputPane?.current) return;
      if (!filePane?.current) return;

      const outputPaneWidth = computeDefaultOutputPaneSize(
        info.pageSize as PageSize | undefined,
        info.renderMode as RenderMode | undefined
      );

      setWidth(
        document.body.clientWidth -
        outputPaneWidth -
        filePane.current!.clientWidth
      );
    }, 1);
  }, [outputPane, filePane, setWidth, info]);

  // This effect should/will only be run if the window is resized, and once
  // when the layout is constructed.
  useEffect(
    () => returnToDefaultSize(),
    [outputPane, filePane, windowWidth, returnToDefaultSize]
  );

  // Return to the default size if we change the size of the window.
  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(document.body.clientWidth);
    };
    window.addEventListener("resize", updateWindowWidth);
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  const onDrag: DraggableEventHandler = useCallback(
    (_, data: DraggableData) => {
      if (width === null) return;

      let newWidth = data.x;
      // Cannot resize past 10vw, for performance reasons
      newWidth = Math.max(newWidth, document.body.offsetWidth * 0.2);
      newWidth = Math.min(
        newWidth,
        mainWidth - document.body.offsetWidth * 0.2
      );

      setWidth(newWidth);
    },
    [width, mainWidth, setWidth]
  );

  useEffect(() => {
    if (width === null || !editorPane?.current) return;
    editorPane.current.style.width = `${width}px`;
  }, [width, editorPane]);

  const maxPaneWidth = useMemo(() => document.body.clientWidth * 0.2, []);

  return width === null || openDocument === null ? (
    <></>
  ) : (
    <Draggable
      axis="x"
      onDrag={onDrag}
      position={{ x: width, y: 0 }}
      bounds={{
        right: mainWidth - maxPaneWidth - 24,
        left: maxPaneWidth,
      }}
      onStart={() => setResizing(true)}
      onStop={() => setResizing(false)}
    >
      <div className="editor-resizer">
        <div className="handle" onDoubleClick={returnToDefaultSize}>
          <VerticalAlignMiddleOutlined rotate={90} />
        </div>
      </div>
    </Draggable>
  );
};

export { EditorResizer };
