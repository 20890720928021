
import "./SidebarV2.scss";
import { Button, Collapse, Layout, Menu, Tooltip } from "antd";
import { CollapseProps } from "antd/es/collapse";
import { FileAddOutlined, FolderAddOutlined, LinkOutlined, SettingOutlined, UploadOutlined } from "@ant-design/icons";
import FilesMenuV2 from "../v2/FilesMenu";
import { useContext, useState } from "react";
import { ProjectSettingsDrawerContext } from "../../contexts/ProjectSettingsDrawerContext";
import { EntryType } from "./fileOperations";
import { FileOperationsContext } from "../../contexts/FilesContext";
import { UploadModalContext } from "../../contexts/UploadModalContext";

enum SidebarItem {
  Documents = "documents",
  Images = "images",
  Links = "links"
}

export const SidebarV2 = () => {
  const projectSettingsDrawer = useContext(ProjectSettingsDrawerContext);
  const [activeKeys, setActiveKeys] = useState<string[]>([SidebarItem.Documents]);
  const uploadModal = useContext(UploadModalContext);

  const { createDocument, createDir } = useContext(FileOperationsContext);

  const items: CollapseProps["items"] =
    [
      {
        key: SidebarItem.Documents,
        label: <>
          <span className="title">Documents</span>
          <div className="item-actions" style={{
            visibility: activeKeys.includes(SidebarItem.Documents) ? "visible" : "hidden",
          }}>
            <Tooltip title="New folder">
              <Button onClick={(e) => { e.stopPropagation(); createDir(undefined, EntryType.Document); }}>
                <FolderAddOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="New file">
              <Button onClick={(e) => { e.stopPropagation(); createDocument(); }}>
                <FileAddOutlined />
              </Button>
            </Tooltip>
          </div>

        </>,
        children: <FilesMenuV2 forType={EntryType.Document} />,
      },
      {
        key: SidebarItem.Images,
        label: <>
          <span className="title">Images</span>
          <div className="item-actions" style={{
            visibility: activeKeys.includes(SidebarItem.Images) ? "visible" : "hidden",
          }}>
            <Tooltip title="New folder">
              <Button onClick={(e) => { e.stopPropagation(); createDir(undefined, EntryType.Resource); }}>
                <FolderAddOutlined />
              </Button>
            </Tooltip>
            <Tooltip title="Upload Image">
              <Button onClick={(e) => { e.stopPropagation(); uploadModal.setVisible(true); }}>
                <UploadOutlined />
              </Button>
            </Tooltip>
          </div>
        </>,
        children: <FilesMenuV2 forType={EntryType.Resource} />,
      },
      {
        key: SidebarItem.Links,
        label: <>
          <span className="title">Links</span>
          <div className="item-actions" style={{
            visibility: activeKeys.includes(SidebarItem.Links) ? "visible" : "hidden",
          }}>
            <Tooltip title="Add Link">
              <Button onClick={(e) => { e.stopPropagation(); }}>
                <LinkOutlined />
              </Button>
            </Tooltip>
          </div>
        </>,
        children: <FilesMenuV2 forType={EntryType.Ref} />,
      }
    ];


  return (
    <Layout.Sider className="sidebar" width={200} style={{ scrollbarGutter: "stable" }}>
      <Collapse
        className="accordion"
        defaultActiveKey={[SidebarItem.Documents]}
        expandIconPosition="left"
        onChange={setActiveKeys}
        items={items}
      />
      <Menu selectable={false} items={[{
        key: "settingsDrawerVisible",
        onClick: () => projectSettingsDrawer.setVisible(true),
        icon: <SettingOutlined />,
        label: "Project settings"
      }]} />
    </Layout.Sider>
  );
}
