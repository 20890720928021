import {
  PictureOutlined,
  FileTextOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Input } from "antd";
import { DataNode } from "antd/lib/tree";
import { EntryType, Files } from "./fileOperations";

// Given a file listing, construct a tree structure that antd understands.
const computeFileTree = (
  files: Files,
  renameTarget: string | null,
  setRenameTarget: (target: string | null) => void,
  renameFile: (key: string, name: string) => void,
  forType?: EntryType
): DataNode[] => {

  const parseFile = (key: string): DataNode => {
    const file = files[key];
    let title: string | JSX.Element = file.name;
    const isLeaf = file.type !== "dir";

    let icon: JSX.Element | undefined = undefined;
    if (file.type === "resource") {
      if (file.name.match(/.jpg$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.gif$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.png$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.svg$/i)) icon = <PictureOutlined />;
      if (file.name.match(/.bmp$/i)) icon = <PictureOutlined />;
    } else if (file.type === "document") {
      icon = <FileTextOutlined />;
    } else if (file.type === "ref") {
      icon = <LinkOutlined />;
    }

    if (renameTarget === key) {
      const done = (e: any) => {
        renameFile(renameTarget, e.target.value);
        setRenameTarget(null);
      };
      title = (
        <Input
          defaultValue={files[renameTarget].name}
          onKeyUp={(e) => e.key === "Enter" && done(e)}
          onBlur={done}
          autoFocus
        />
      );
    }

    return { key, title, icon, isLeaf };
  };


  // Sort child entries by name, putting directories first.
  const sortNodeList = (nodeList: DataNode[]) => {
    nodeList.sort((a: DataNode, b: DataNode) => {
      const fa = files[String(a.key)]!;
      const fb = files[String(b.key)]!;
      const typeValue = (type: EntryType) => {
        switch (type) {
          case EntryType.Dir:
            return 1;
          case EntryType.Ref:
            return 2;
          case EntryType.Document:
            return 3;
          case EntryType.Resource:
            return 4;
        }
      };
      const typeDiff = typeValue(fa.type) - typeValue(fb.type);
      if (typeDiff === 0) return fa.name.localeCompare(fb.name);
      else {
        return typeDiff;
      }
    });
    return nodeList;
  };

  // Construct the tree structure from the parent relations.

  // These nodes are the ones at the top level
  const topLevelNodes = new Set(Object.keys(files));
  Object.values(files).forEach(f => {
    Object.keys(f.children ?? {}).forEach((k) => topLevelNodes.delete(k));
  })

  // Construct the tree in depth-first order.
  const buildNode = (key: string): (DataNode | null) => {
    if (!files[key]) return null;
    const node = files[key];
    const nodeParsed = parseFile(key);

    if (node.type === EntryType.Dir) {
      // Disable folders for references
      if (forType === EntryType.Ref) return null;
      const children = Object.keys(node.children ?? []).map(k => {
        return buildNode(k)
      })
        .filter((child) => child !== null);

      if (children.length === 0 && forType && forType !== node.for)
        return null;
      nodeParsed.children = children as DataNode[];
      sortNodeList(nodeParsed.children);
      return nodeParsed;
    }

    else {
      if (forType && (node.type !== forType)) return null;
      return nodeParsed;
    }
  }

  const forest = Array.from(topLevelNodes.keys())
    .map(k => buildNode(k))
    .filter(n => n !== null) as DataNode[];

  sortNodeList(forest);
  return forest;

};

export { computeFileTree };
