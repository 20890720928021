import React from "react";
import { EditorSizeProvider } from "./EditorSizeContext";
import { FilesProvider } from "./FilesContext";
import { OpenDocumentProvider } from "./OpenDocumentContext";
import { PaneProvider } from "./PaneContext";
import { ProjectInfoProvider } from "./ProjectInfoContext";
import { ProjectSettingsDrawerProvider } from "./ProjectSettingsDrawerContext";
import { RenderProvider } from "./RenderContext";
import { UploadModalProvider } from "./UploadModalContext";
import { LocalProjectSettingsProvider } from "./LocalProjectSettingsContext";

const ProjectViewProviderStack = ({
  children,
}: {
  children: JSX.Element | JSX.Element[];
}) => (
  <UploadModalProvider>
    <ProjectInfoProvider>
      <LocalProjectSettingsProvider>
        <ProjectSettingsDrawerProvider>
          <FilesProvider>
            <OpenDocumentProvider>
              <RenderProvider>
                <PaneProvider>
                  <EditorSizeProvider>{children}</EditorSizeProvider>
                </PaneProvider>
              </RenderProvider>
            </OpenDocumentProvider>
          </FilesProvider>
        </ProjectSettingsDrawerProvider>
      </LocalProjectSettingsProvider>
    </ProjectInfoProvider>
  </UploadModalProvider>
);

export { ProjectViewProviderStack };
