import { RefObject } from "react";

/*
 * This type is handed as a parameter to all functions in the markdown
 * rendering process. It is assumed that any functions which take this type
 * as input can throw a RenderingError which will be caught.
 */
export type RenderingContext = {
  projectId: string;
  // files: Files;
  main: string | undefined;
  outputRef: RefObject<HTMLDivElement>;
  // fileOperations: FileOperations;
  clearCache: boolean;
  openDocument: string | null;
  openDocumentData: string;
  docPrefix: string | undefined;
};
/* to destructure the renderingContext, use the following:

  const {
    projectId,
    files,
    main,
    outputRef,
    fileOperations: { getFullPath, getResourceURL },
    clearCache,
    openDocument,
    openDocumentData,
  } = context;

*/

export type RenderingHelpers = {
  pathToKey: { [pid: string]: { [filepath: string]: string } };
  keyToPath: { [pid: string]: { [key: string]: string } };
  documentKeys: { [pid: string]: string[] };
  documentKeysFlat: DocKey[];
  resourcesByPath: { [pid: string]: { [filepath: string]: string } };
};

/**
 * DocKeys are used to avoid accidentally stringly-typing everything.
 */
export type DocKey = { pid: string; key: string };
const joiner = "\u001f";
export const toKeyHash = (k: DocKey): string => {
  return k.pid + joiner + k.key;
};
export const toKey = (h: string): DocKey => {
  const [pid, key] = h.split(joiner);
  return { pid, key };
};
