import { Modal, Input, Select } from "antd";
import { useContext, useState, useCallback, useMemo } from "react";
import { SourceData, searchSources, getProjectInfo } from "../../../databaseHelpers";
import { UserContext } from "../../../UserContext";
import { FileOperationsContext } from "../../contexts/FilesContext";
import "./SourceModal.scss";

const SourceModal = ({
  sourceInfo,
  setSourceInfo,
}: {
  sourceInfo: SourceInfo | null;
  setSourceInfo: (info: SourceInfo | null) => void;
}) => {
  const user = useContext(UserContext);
  const { createSource } = useContext(FileOperationsContext);
  const [sourcesData, setSourcesData] = useState<SourceData[]>([]);

  const onSearch = useCallback(async (search: string) => {
    setSourcesData([]);
    if (search.length < 3) return;

    // Search through all possible locations
    const allSources = await searchSources(search, user?.uid ?? "");

    setSourcesData(allSources);
  }, [user?.uid]);

  const setName = useCallback((n: string) => {
    setSourceInfo({ ...sourceInfo!, name: n });
  }, [setSourceInfo, sourceInfo]);

  const onSetSource = useCallback(async (s: string) => {
    console.log("Source set to " + s);
    const projectName = (await getProjectInfo(s)).info.name;
    setSourceInfo({ ...sourceInfo!, ref: s, name: projectName });
  }, [setSourceInfo, sourceInfo]);


  const doCreate = useMemo(() => !sourceInfo?.id, [sourceInfo]);
  const createReady = useMemo(
    () => sourceInfo?.name && sourceInfo.ref,
    [sourceInfo]
  );

  const onOk = useCallback(async () => {
    const makeSource = () => {
      createSource(sourceInfo?.ref, sourceInfo?.name, sourceInfo?.parent);
    };
    if (doCreate) {
      await makeSource();
    }
    setSourceInfo(null);
  }, [createSource, doCreate, setSourceInfo, sourceInfo]);

  return (
    <Modal
      open={sourceInfo !== null}
      onCancel={() => setSourceInfo(null)}
      title="Create/Edit Source"
      okText={doCreate ? "Create Source" : "OK"}
      onOk={onOk}
      okButtonProps={{ disabled: !createReady }}
    >
      <p className="input-label">Name:</p>
      <Input
        type="text"
        name="source-name"
        value={sourceInfo?.name}
        placeholder="e.g. Front Cover"
        onChange={(e) => setName(e.target.value)}
      ></Input>

      <p className="input-label">Select Source:</p>
      <Select
        showSearch
        value={sourceInfo?.name}
        autoClearSearchValue
        showArrow={false}
        placeholder="Search for project by name..."
        onSearch={onSearch}
        notFoundContent={null}
        className="set-source"
        filterOption={false}
        defaultActiveFirstOption={false}
        onChange={(v) => onSetSource(v)}
        onBlur={() => {
          onSearch("");
        }}
      >
        {sourcesData.map(({ ref, name, workspaceName }) => (
          <Select.Option key={ref} value={ref}>
            <div className="project-select">
              <p className="project-name">{name}</p>
              {workspaceName && (
                <p className="workspace-name">{workspaceName}</p>
              )}
            </div>
          </Select.Option>
        ))}
      </Select>
    </Modal>
  );
};

/**
 * A watered-down subset of the source information, corresponding to the info which gets stored in the file tree.
 */
export type SourceInfo = {
  id?: string;
  name?: string;
  ref?: string;
  parent?: string;
};

export { SourceModal };
